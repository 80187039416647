import ReviewApi from "@/api/ReviewApi/ReviewApi";


export class ReviewApiFuc {
    /**
     * 작성가능한 후기 리스트
     * @param param
     * @returns {Promise<any>}
     * @constructor
     */
    static async ReviewsYouCanWrite (param) {
        try {
            const data = await ReviewApi.ListOfReviewsThatCanBeWritten(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * 작성한 후기 리스트
     * @param param
     * @returns {Promise<any>}
     * @constructor
     */
    static async ReviewListRequest (param) {
        try {
            const data = await ReviewApi.reviewList(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * 리뷰작성 상품  정보 가져오는 api
     * @param param
     * @returns {Promise<any>}
     * @constructor
     */

    static async ReviewInsertInfoRequest (param) {
        try {
            const data = await ReviewApi.requestReviewInfo(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * 리뷰작성 하기 api
     * @param param
     * @returns {Promise<any>}
     */
    static async reviewAnsWerInsert (param) {
        try {
            const data = await ReviewApi.reviewInSert(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * 내 리뷰 수정
     * @param param
     * @returns {Promise<*>}
     */
    static async myReviewEditRequest (param) {
        try {
            const data = await ReviewApi.myReviewEdit(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }
}