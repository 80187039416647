import {defineStore} from 'pinia'


export const useCustomSurvey = defineStore('CustomSurvey', {
    state: () => {
        return {
            stepOne: null,
            stepAddress: null,
            stepTwo: null,
            stepThreeValue: null,
            stepForValue: null,
            stepFiveValue: null,
            customerData: null,
            alarmYn: null,
            isNext: false,
            saveStepFiveData: null,
            stepOptionSelect: null,
            ticketGubun: null
        }
    },
    actions: {
        // 1회권인지 구독인지 구분
        updateTicketGubun (data) {
            this.ticketGubun = data
        },
        setStepOneValue (data) {
            this.stepOne = data;
        },
        setStepTwoValue (data) {
            this.stepTwo = data;
        },
        updateStepOptionSelect (data) {
            this.stepOptionSelect = data
        },
        setStepAddress (data) {
            this.stepAddress = data;
        },
        setStepThreeValue (data) {
            this.stepThreeValue = data;
        },
        setStepForValue (data) {
            this.stepForValue = data
        },
        setStepFiveValue (data) {
            this.stepFiveValue = data
        },
        setCustomerData (data) {
            this.customerData = data
        },
        setUpdateIsNext (data) {
            this.isNext = data
        },
        setAlarmYn (data) {
            this.alarmYn = data
        },
        setSaveStepFiveData (data) {
            this.saveStepFiveData = data
        },
        resetAddress () {
            this.stepAddress = null
        },
        resetAlarmYn () {
            this.alarmYn = null
        },
        reset () {
            this.stepOne = null;
            this.stepAddress = null;
            this.stepTwo = null;
            this.stepThreeValue = null;
            this.stepForValue = null;
            this.stepFiveValue = null;
            this.customerData = null;
            this.alarmYn = null;
            this.isNext = false;
            this.stepOptionSelect = null;
        },
    },
    getters: {},
    persist: true
})
