import {useServiceStore} from "@/stores/serviceStore/useServiceStore";

export default {
    path: '/customerService',
    children: [
        {
            path: '',
            name: 'customerService',
            label: '고객센터',
            component: () => import('@/views/service/ServiceVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'frequentlyAskedQuestions',
            name: 'customerService.frequentlyAskedQuestions',
            label: '자주 묻는 질문',
            component: () => import('@/views/service/FrequentlyAskedQuestionsVue.vue'),
            meta: {
                title: '하루세탁'
            },
            beforeEnter: (to, from, next) => {
                const serviceStore = useServiceStore();
                if (serviceStore.frequentlyAskedQuestionsList) {
                    next();
                } else {
                    next('customerService');
                }
            },
        },
        {
            path: 'noticeList',
            name: 'customerService.noticeList',
            label: '공지사항',
            component: () => import('@/views/service/NoticeServiceVue.vue'),
            meta: {
                title: '하루세탁'
            },
            beforeEnter: (to, from, next) => {
                const serviceStore = useServiceStore();
                if (serviceStore.noticeList) {
                    next();
                } else {
                    next('customerService');
                }
            },
        },
        {
            path: 'affiliateStoreOpening',
            name: 'customerService.affiliateStoreOpening',
            label: '제휴입점',
            component: () => import('@/views/service/AffiliateStoreOpening.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'noticeList/:id',
            name: 'customerService.noticeListDetail',
            label: '공지사항',
            component: () => import('@/views/service/NoticeServiceDetailVue.vue'),
            meta: {
                title: '하루세탁'
            },
            beforeEnter: (to, from, next) => {
                const serviceStore = useServiceStore();
                if (serviceStore.noticeDetail) {
                    next();
                } else {
                    next('customerService');
                }
            },
        },
    ]
}
