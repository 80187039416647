export default {
    path: '/alarm',
    children: [
        {
            path: '',
            name: 'alarm',
            label: '알람',
            component: () => import('@views/alarm/AlarmListVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
    ]
}
