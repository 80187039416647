<script>
import {getCurrentInstance, reactive, toRefs} from "vue";


export default {
  name: "TypeTwoHeaderVue",
  props: ['title'],
  setup () {
    const {props, emit} = getCurrentInstance();
    const state = reactive({})
    return {
      ...toRefs(state),
      props,
      handleClosePopView () {
        emit('closePopView', true);
      }
    }
  }
}
</script>

<template>
  <div class="header_wrap">
    <h2 class="titles">{{ props.title }}</h2>
    <button class="close_btn" type="button" @click="handleClosePopView">
      <img alt="" src="@/assets/icon/close_icon.svg"/>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.header_wrap
{
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  height          : 64px;
  padding         : 0 24px;
  .titles
  {
    padding-left   : 24px;
    margin-right   : auto;
    font-family    : AppleSDGothicNeoSB00;
    font-size      : 16px;
    font-style     : normal;
    font-weight    : 400;
    line-height    : 24px; /* 150% */
    letter-spacing : -0.48px;
    text-align     : center;
    width          : calc(100% - 24px);
  }
  .close_btn
  {
    margin-left : auto;
    width       : 24px;
    height      : 24px;
    img
    {
      width : 100%;
    }
  }
}
</style>