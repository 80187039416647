<script>
import {useRoute, useRouter} from 'vue-router'
import {computed, onBeforeUnmount, onMounted, reactive, watch} from 'vue'
import LoadingVues from "@/components/LoadingVues.vue";
import {useQuasar} from "quasar";
import ModalVue from "@/components/common/ModalVue.vue";
import {useAuthStore} from "@/stores/useAuthStore";
import {useUpdateStore} from "@/stores/useUpdateStore";


export default {
  name: 'App',
  components: {ModalVue, LoadingVues},
  setup () {
    const update = useUpdateStore();
    const authInfo = useAuthStore();
    const $q = useQuasar()
    let timer
    const state = reactive({
      shouldUpdate: false
    })
    const route = useRoute();
    const router = useRouter();

    watch(
        () => authInfo.memberToken,
        (newToken) => {
          update.updateSholdUpdate();
        }
    );
    console.log(update.shouldUpdate)
    onBeforeUnmount(() => {
      if (timer !== void 0) {
        clearTimeout(timer)
        $q.loading.hide()
      }
    });
    window.auth_pop = (data) => {
      console.log(data);
    }
    window.showLoading = () => {
      $q.loading.show({
        message: 'Some important <b>process</b> is in progress.<br/><span class="text-amber text-italic">Please wait...</span>',
        html: true,
      })

      // hiding in 3s
      timer = setTimeout(() => {
        $q.loading.hide()
        timer = void 0
      }, 500)
    };
    window.tostAlert = (message) => {
      if (message) {
        $q.notify({
          message: message,
          timeout: 2000,
          classes: 'my_notice'
        })
      } else {
        $q.notify({
          message: '알림 메세지를 입력 해주세요.',
          timeout: 2000,
          classes: 'my_notice'
        });
      }

    };
    onMounted(()=>{
      // 더블탭 확대 방지
      document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }, false);

      var lastTouchEnd = 0;

      document.documentElement.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        } lastTouchEnd = now;
      }, false);
    });
    // const routerViewKey = computed(() => `${route.path}-${update.shouldUpdate}`);
    const routerViewKey = computed(() => `${route.path}-${update.shouldUpdate}`);
    return {
      routerViewKey,
      update,
      route,
    }
  }
}
</script>

<template>
  <!--  <header></header>-->
  <RouterView :key="routerViewKey"/>
</template>

<style lang="scss" scoped>
</style>
