import {defineStore} from 'pinia'


export const useCustomerStep = defineStore('customerStep', {
    state: () => {
        return {
            step: 1,
            stepSucces: false,
            stepValue: {
                one: null,
                two: null,
                three: null,
                for: null,
                five: null,
                address: null,
                pushAlarm: null
            },
            rialAnswer: {
                one: null,
                two: null,
                three: null,
                for: null,
                five: null
            },
            dateSaveSelectObj: {
                idx: null,
                dateIdx: null,
                dateKey: null,
            },
            isSuccess: false,
            isNext: false,
            isAddressSelect: false,
            // 체험해보기 인지 아닌지 판별
            isTryOut: false,
            // 향기 이름
            flavor_name: null
        }
    },
    actions: {
        // 향기 오리지널 이름
        updateFlavorName (data) {
            this.flavor_name = data
        },
        // 설문 완료 했는지 체크
        updateStepSucces (data) {
            this.stepSucces = data;
        },
        // 체험해보기 업데이트
        updateTryOut (data) {
            this.isTryOut = data;
        },
        resetTryOut () {
            this.isTryOut = false;
        },
        isSuccessUpdate (value) {
            this.isSuccess = value;
        },
        incrementStep (value) {
            this.step = value;
        },
        updateAddress (value) {
            this.stepValue.address = value
        },
        isUpdateAddressSelect (value) {
            this.isAddressSelect = value
        },
        isAlarmUpdate (value) {
            this.stepValue.pushAlarm = value;
        },
        updateDateSaveSelectObj (idx, dateIdx, dateKey) {
            this.dateSaveSelectObj.idx = idx;
            this.dateSaveSelectObj.dateIdx = dateIdx;
            this.dateSaveSelectObj.dateKey = dateKey;
        },
        depCrementtUpdate (value) {
            this.step = value;
        },
        answerUpdate (type, value) {
            console.log('@@@@@@@@@@@@@');
            console.log(type);
            console.log(value);
            console.log('@@@@@@@@@@@@@');
            switch (type) {
                case 'one':
                    this.stepValue.one = value;
                    break;
                case 'two':
                    this.stepValue.two = value;
                    switch (value) {
                        case 1:
                            this.rialAnswer.two = '1인'
                            break;
                        case 2:
                            this.rialAnswer.two = '2인'
                            break;
                        case 3:
                            this.rialAnswer.two = '3인'
                            break;
                        case 4:
                            this.rialAnswer.two = '4인 이상'
                            break;
                        case 5:
                            this.rialAnswer.two = '15인 이상'
                            break;
                    }
                    break;
                case 'three':
                    this.stepValue.three = value;
                    switch (value) {
                        case 1:
                            this.rialAnswer.three = '다우니'
                            break;
                        case 2:
                            this.rialAnswer.three = '애경'
                            break;
                        case 3:
                            this.rialAnswer.three = '피오니'
                            break;
                        case 4:
                            this.rialAnswer.three = '사용안함'
                            break;
                    }
                    break;
                case 'for':
                    this.stepValue.for = value;
                    switch (value) {
                        case 1:
                            this.rialAnswer.for = '격주 1회'
                            break;
                        case 2:
                            this.rialAnswer.for = '주 1회'
                            break;
                        case 3:
                            this.rialAnswer.for = '1회권'
                            break;
                    }
                    break;
                case 'five':
                    this.stepValue.five = value;
                    this.rialAnswer.five = value;
                    break;
                case 'push':
                    this.stepValue.pushAlarm = value;
                    break;
                case 'address':
                    this.stepValue.address = value;
                    break;
            }

        },
        updateIsNext (value) {
            this.isNext = value;
        },
        reset () {
            this.step = 1
            this.stepValue.one = null
            this.stepValue.two = null
            this.stepValue.three = null
            this.stepValue.for = null
            this.stepValue.five = null
            this.isSuccess = false
            this.dateSaveSelectObj.idx = null
            this.dateSaveSelectObj.dateIdx = null
            this.dateSaveSelectObj.dateKey = null
            this.stepValue.address = null
            this.stepValue.pushAlarm = null
            this.isAddressSelect = null
            this.isSuccess = null
            this.flavor_name = null
            localStorage.removeItem('useCustomerValue');
        },
        resetStepSucces () {
            this.stepSucces = false
        },
        // 향기 이름 삭제
        resetFlavorName () {
            this.flavor_name = null
        }
    },
    getters: {},
    persist: true
})
