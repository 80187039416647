import {defineStore} from 'pinia'

export const useServiceStore = defineStore('serviceStore', {
    state: () => {
        return {
            // 공지사항리스트
            noticeList: null,
            // 자주묻는질문
            frequentlyAskedQuestionsList: null,
            noticeDetail: null
        }
    },
    actions: {
        // 공지사항 상세 업데이트
        updateNoticeDetailData(data) {
            this.noticeDetail = data;
        },
        // 공지사항리스트
        updateNoticeList(data) {
            this.noticeList = data;
        },
        // 자주묻는질문
        updateFrequentlyAskedQuestionsList(data) {
            this.frequentlyAskedQuestionsList = data;
        },
        // 공지사항리스트 리셋
        resetUpdateNoticeList() {
            this.noticeList = null;
        },
        // 자주묻는질문 리셋
        resetFrequentlyAskedQuestionsList() {
            this.frequentlyAskedQuestionsList = null;
        },
        // 공지사항 상세 초기화
        resetNoticeDetailData() {
            this.noticeDetail = null;
        }
    },
    getters: {},
    persist: false
})
