export default {
    path: '/mypage',
    children: [
        {
            path: '',
            name: 'mypage',
            label: '마이페이지',
            component: () => import('@/views/mypage/MyPageVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'address',
            name: 'mypage.address',
            label: '배송_회수지관리',
            component: () => import('@/views/mypage/EditAddressVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'subscribe',
            name: 'subscribe',
            label: '구독',
            component: () => import('@/views/mypage/SubscribeVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'detail',
            children: [
                {
                    path: '',
                    name: 'detail',
                    label: '구독내역',
                    component: () => import('@/views/mypage/SubscriptionHistoryDetailVue.vue'),
                    meta: {
                        title: '하루세탁'
                    }
                },
                {
                    path: 'detail_info',
                    name: 'detail.detail_info',
                    label: '구독내역 상세',
                    component: () => import('@/views/mypage/SubscriptDetailInfoVue.vue'),
                    meta: {
                        title: '하루세탁'
                    },

                }
            ]
        },
        {
            path: 'purchaseHistory',
            name: 'purchaseHistory',
            label: '구매내역',
            component: () => import('@/views/mypage/PurchaseHistoryVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'myCoupon',
            name: 'myCoupon',
            label: '내 쿠폰',
            component: () => import('@/views/mypage/MyCouPonVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'Myinfo',
            name: 'Myinfo',
            label: '내 정보',
            component: () => import('@/views/mypage/MyInfoVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'editMemberInformation',
            name: 'editMemberInformation',
            label: '회원정보수정',
            component: () => import('@/views/mypage/EditMemberInfoVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'withdrawal',
            name: 'withdrawal',
            label: '회원탈퇴',
            component: () => import('@/views/mypage/WithdrawalVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'setting',
            name: 'setting',
            label: '설정',
            component: () => import('@/views/mypage/SettingVue.vue'),
            meta: {
                title: '하루세탁'
            },
        }
    ]
}
