export default {
    path: '/find',
    name:'find',
    children: [
        {
            path: 'id',
            name: 'id',
            label: '아이디찾기',
            component: () => import('@/views/findId/FindIdVue.vue')
        },
        {
            path: 'id_type_b',
            name: 'id_type_b',
            label: '인증번호',
            component: () => import('@/views/findId/FindIdCertificationNumberVue.vue')
        },
        {
            path: 'id_type_C',
            name: 'id_type_C',
            label: '인증번호발',
            component: () => import('@/views/findId/FindIdCertificationNumberTypeBVue.vue')
        },
        {
            path: 'result',
            name: 'result',
            label: '아이디찾기결과',
            component: () => import('@/views/findId/ResultFindIdVue.vue'),
        },
        // 비밀번호 찾기
        {
            path: 'pw',
            name: 'pw',
            label: '비밀번호찾기',
            component: () => import('@/views/findpw/FindPwVue.vue')
        },
        {
            path: 'identity',
            name: 'identity',
            label: '본인인증',
            component: () => import('@/views/findpw/FindPwIdentityVue.vue')
        },
        {
            path: 'repassword',
            name: 'repassword',
            label: '비밀번호재설정',
            component: () => import('@/views/findpw/FindpwNewPwVue.vue')
        },
        {
            path: 'repasswordCheck',
            name: 'repasswordCheck',
            label: '비밀번호재설정확',
            component: () => import('@/views/findpw/FindpwNewPwReVue.vue')
        },
        {
            path: 'succes',
            name: 'succes',
            label: '비밀번호 재설정 성공',
            component: () => import('@/views/findpw/FindPwNewPwSuccVue.vue')
        },
    ]
}