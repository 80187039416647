import {useAuthStore} from "@/stores/useAuthStore";


export default {
    path: '/customer',
    beforeEnter: (to, from, next) => {
        const authInfo = useAuthStore();
        if (authInfo.memberToken) {
            next();
        } else {
            next('/auth/join');
        }
    },
    children: [
        {
            path: '',
            name: 'customer',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepOneVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'two',
            name: 'customerTwo',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepTwoVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'StemSucces',
            name: 'StemSucces',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StemSuccesVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'StepThree',
            name: 'StepThree',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepThreeVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'StepFor',
            name: 'StepFor',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepForVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'StepDryOption',
            name: 'StepDryOption',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepDryOptionVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'StepFive',
            name: 'StepFive',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepFiveVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'address',
            name: 'address',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepAddressSelectVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'alram',
            name: 'customer.alram',
            label: '맞춤구독',
            component: () => import('@/components/StepVue/StepPushAlramVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'notice',
            name: 'customer.notice',
            label: '맞춤구독설명',
            component: () => import('@/components/StepVue/StepPushAlramNoticeVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'enterprice',
            name: 'customer.enterprice',
            label: '기업전용 상품 신청서',
            component: () => import('@/components/EnterpriceVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'succes',
            name: 'customer.succes',
            label: '기업전용 상품 신청서 성공',
            component: () => import('@/components/SuccesEnterPriceAddVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
    ]
}


