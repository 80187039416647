import {useOrderStore} from "@/stores/useOrderStore";

export default {
    path: '/order',
    children: [
        {
            path: 'pay',
            name: 'order.pay',
            label: '구독정기결제',
            component: () => import('@/views/order/SubscribeOrderVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'orderPay',
            name: 'order.orderPay',
            label: '구매하기',
            component: () => import('@/views/order/OrderPurchaseVue.vue'),
            meta: {
                title: '하루세탁'
            },
            beforeEnter: (to, from, next) => {
                const useOrder = useOrderStore();
                if (useOrder.orderData) {
                    next();
                } else {
                    tostAlert('잘못된 접근 입니다.');
                    console.warn('orderPay')
                    next('/');
                }
            },
        },
        {
            path: 'changeDeliveryAddress',
            name: 'order.changeDeliveryAddress',
            label: '배송지변경',
            component: () => import('@/views/mypage/EditAddressVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
        {
            path: 'complete/:order',
            name: 'order.complete',
            label: '주문완료',
            component: () => import('@/views/order/OrderCompleteVue.vue'),
            meta: {
                title: '하루세탁'
            },
        },
    ]
}
