<script>
export default {
  name: "LoadMyCouponVue"
}
</script>

<template>
  <div class="coupont_list_con">
    <ul class="coupon_list">
      <li class="coupon_list_item">
        <div class="top">
          <div class="bage">
            <q-skeleton height="20px" type="QBadge" width="50px"/>
          </div>
          <q-skeleton height="20px" type="text" width="20%"/>
        </div>
        <div class="bottom" style="margin-top: 10px">
          <q-skeleton height="20px" type="text" width="100%"/>
        </div>
      </li>
      <li class="coupon_list_item">
        <div class="top">
          <div class="bage">
            <q-skeleton height="20px" type="QBadge" width="50px"/>
          </div>
          <q-skeleton height="20px" type="text" width="20%"/>
        </div>
        <div class="bottom" style="margin-top: 10px">
          <q-skeleton height="20px" type="text" width="100%"/>
        </div>
      </li>
      <li class="coupon_list_item">
        <div class="top">
          <div class="bage">
            <q-skeleton height="20px" type="QBadge" width="50px"/>
          </div>
          <q-skeleton height="20px" type="text" width="20%"/>
        </div>
        <div class="bottom" style="margin-top: 10px">
          <q-skeleton height="20px" type="text" width="100%"/>
        </div>
      </li>
      <li class="coupon_list_item">
        <div class="top">
          <div class="bage">
            <q-skeleton height="20px" type="QBadge" width="50px"/>
          </div>
          <q-skeleton height="20px" type="text" width="20%"/>
        </div>
        <div class="bottom" style="margin-top: 10px">
          <q-skeleton height="20px" type="text" width="100%"/>
        </div>
      </li>
      <li class="coupon_list_item">
        <div class="top">
          <div class="bage">
            <q-skeleton height="20px" type="QBadge" width="50px"/>
          </div>
          <q-skeleton height="20px" type="text" width="20%"/>
        </div>
        <div class="bottom" style="margin-top: 10px">
          <q-skeleton height="20px" type="text" width="100%"/>
        </div>
      </li>
      <li class="coupon_list_item">
        <div class="top">
          <div class="bage">
            <q-skeleton height="20px" type="QBadge" width="50px"/>
          </div>
          <q-skeleton height="20px" type="text" width="20%"/>
        </div>
        <div class="bottom" style="margin-top: 10px">
          <q-skeleton height="20px" type="text" width="100%"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.coupont_list_con
{
  padding   : 0 24px;
  overflow  : auto;
  height    : calc(100vh - 350px);
  max-width : 500px;
  margin    : 0 auto;
  .coupon_list
  {
    height : 100%;
    .coupon_list_item
    {
      background    : #fff;
      padding       : 16px;
      border-radius : 12px;
      &:not(:last-child)
      {
        margin-bottom : 10px;
      }
      .top
      {
        display         : flex;
        justify-content : flex-start;
        align-items     : center;
        .bage
        {
          display        : inline-block;
          height         : 20px;
          border-radius  : 6px;
          padding        : 0 5px;
          font-family    : AppleSDGothicNeoM00;
          font-size      : 12px;
          font-style     : normal;
          font-weight    : 400;
          line-height    : 18px; /* 150% */
          letter-spacing : -0.36px;
          &.subscript
          {
            background : #DFECFC;
            color      : #4583FF;
          }
        }
        .detail_text
        {
          font-family    : AppleSDGothicNeoSB00;
          font-size      : 16px;
          font-style     : normal;
          font-weight    : 400;
          line-height    : 24px; /* 150% */
          letter-spacing : -0.48px;
          color          : #000;
          margin-left    : 6px !important;
        }
      }
      .bottom
      {
        font-family    : AppleSDGothicNeoR00;
        font-size      : 14px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 20px; /* 142.857% */
        letter-spacing : -0.42px;
        color          : #999;
      }
    }
  }
}
</style>