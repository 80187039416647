import {useAuthStore} from "@/stores/useAuthStore";

export default {
    path: '/myactivity',
    beforeEnter: (to, from, next) => {
        const authInfo = useAuthStore();
        if (authInfo.memberToken) {
            next();
        } else {
            next('/auth/join');
        }
    },
    children: [
        {
            path: '',
            name: 'myactivity',
            label: '나의활동',
            component: () => import('@/views/myActivity/myActivityVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'inquiry',
            name: 'inquiry',
            label: '문의하기',
            component: () => import('@/views/myActivity/InquiryVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'review',
            name: 'review',
            label: '후기',
            component: () => import('@/views/myActivity/ReviewVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'addInquiry',
            name: 'addInquiry',
            label: '문의하기작성',
            component: () => import('@/views/myActivity/AddInquiryVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
        {
            path: 'addInquiry/:id',
            name: 'addInquiry:id',
            label: '문의하기수정',
            component: () => import('@/views/myActivity/AddInquiryVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
    ]
}
