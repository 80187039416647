import axios from "@/axios/index";


export default {
    ListOfReviewsThatCanBeWritten (param) {
        return axios.post('/api/board/reviewWriteList', param)
    },
    reviewList (param) {
        return axios.post('/api/board/reviewList', param)
    },
    requestReviewInfo (param) {
        return axios.post('/api/board/reviewWriteInfo', param)
    },
    reviewInSert (param) {
        return axios.post('/api/board/reviewWrite', param, {
            headers: {
                'Content-Type': 'multipart/form-data',
                processData: false,
            },
        })
    },
    myReviewEdit (param) {
        return axios.post('/api/board/reviewUpdate', param, {
            headers: {
                'Content-Type': 'multipart/form-data',
                processData: false,
            },
        })
    }
};
