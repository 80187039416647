export default {
    path: '/dryTip',
    children: [
        {
            path: '',
            name: 'dryTip',
            label: '나의세탁노하우',
            component: () => import('@/views/dryTip/DryTipVue.vue'),
            meta: {
                title: '하루세탁'
            }
        },
    ]
}
