import {defineStore} from 'pinia'

export const useUpdateStore = defineStore('updateStore', {
    state: () => {
        return {
            shouldUpdate: 0
        }
    },
    actions: {
        updateShouldUpdate(data) {
            this.shouldUpdate = data;
        },
        updateSholdUpdate() {
            this.shouldUpdate++
        },
        reset() {
            this.shouldUpdate = false
        }
    },
    getters: {},
    persist: false
})
