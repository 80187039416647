<script setup>

import {useRouter} from "vue-router";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import NavigationVue from "@Components/NavigationVue.vue";
import LoadMyCouponVue from "@Components/loadingComponents/LoadMyCouponVue.vue";
import {MainApiRequest} from "@/api/main/mainApiRequest";


const timeOut = ref(null);
const router = useRouter();
const state = reactive({
  tipList: [
    {
      title: 'Step1.하드세탁',
      subList: ['직접 하는 하드 세탁 처럼 단독 하드 세탁']
    }
  ],
  couponList: [],
  isLoadData: false
});

const productCouponAllList = () => {
  MainApiRequest.getProductAllCouponRequest({
    page: '1'
  }).then(res => {
    console.log("-> res", res);
    state.couponList = res.data;
    timeOut.value = setTimeout(() => {
      state.isLoadData = true;
    }, 300);
  });
}
onMounted(() => {
  productCouponAllList();
});
onUnmounted(() => {
  clearTimeout(timeOut.value);
});

</script>

<template>
  <div class="my_coupon_wrap">
    <div class="my_coupon_inner">
      <div v-if="state.isLoadData" class="coupont_list_con">
        <ul v-if="state.couponList.length > 0" class="coupon_list">
          <li v-for="(coupon,idx) in state.couponList" :key="idx" class="coupon_list_item">
            <div class="top">
              <!--              <span :class="{subscript:coupon.routine_yn === 'Y'}"-->
              <!--                    class="bage ">{{ coupon.routine_yn === 'Y' ? '구독' : '결제' }}</span>-->
              <p class="detail_text">{{ coupon.coupon_name }}</p>
            </div>
            <div class="bottom">
              {{ coupon.coupon_desc }}
            </div>
          </li>
        </ul>
        <div v-else class="notice_text">사용 가능한 쿠폰이 없습니다.</div>
      </div>
      <LoadMyCouponVue v-else/>
      <div class="notice_text_con">
        <p>쿠폰사용 TIP</p>
        <ul class="notice_text_list">
          <li class="notice_text_item">상품쿠폰은 상품 단위로 적용되며 할인금액은 원단위로 적용됩니다. (단, 쿠폰적용제외 상품은 쿠폰 사용 불가)</li>
          <li class="notice_text_item">할인쿠폰을 이용한 주문을 취소/반품하실 때 일부 쿠폰의 경우 재발급되지 않습니다.</li>
          <li class="notice_text_item">쿠폰 적용 상품은 당사 사정에 따라 변경될 수 있습니다.</li>
          <li class="notice_text_item">최초 발급 기준 유효기간이 지난 쿠폰은 재사용이 불가능합니다.</li>
        </ul>
      </div>
      <NavigationVue/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notice_text
{
  text-align : center;
  font-size  : 14px;
}
.my_coupon_wrap
{
  max-width : 500px;
  margin    : 0 auto;
  .my_coupon_inner
  {
    // 쿠폰리스트
    .coupont_list_con
    {
      padding  : 0 24px;
      overflow : auto;
      height   : calc(100vh - 350px);
      .coupon_list
      {
        height : 100%;
        .coupon_list_item
        {
          background    : #fff;
          padding       : 16px;
          border-radius : 12px;
          &:not(:last-child)
          {
            margin-bottom : 10px;
          }
          .top
          {
            display         : flex;
            justify-content : flex-start;
            align-items     : center;
            .bage
            {
              display        : inline-block;
              height         : 20px;
              border-radius  : 6px;
              padding        : 0 5px;
              font-family    : AppleSDGothicNeoM00;
              font-size      : 12px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 18px; /* 150% */
              letter-spacing : -0.36px;
              background     : #ECECEc;
              color          : #333;
              &.subscript
              {
                background : #DFECFC;
                color      : #4583FF;
              }
            }
            .detail_text
            {
              font-family    : AppleSDGothicNeoSB00;
              font-size      : 16px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 24px; /* 150% */
              letter-spacing : -0.48px;
              color          : #000;
              //margin-left    : 6px !important;
            }
          }
          .bottom
          {
            font-family    : AppleSDGothicNeoR00;
            font-size      : 14px;
            font-style     : normal;
            font-weight    : 400;
            line-height    : 20px; /* 142.857% */
            letter-spacing : -0.42px;
            color          : #999;
          }
        }
      }
    }
    .notice_text_con
    {
      position    : fixed;
      bottom      : 104px;
      left        : 50%;
      transform   : translateX(-50%);
      width       : calc(100% - 24px);
      padding     : 0 24px;
      border-top  : solid 1px #E7EAF0;
      padding-top : 24px;
      max-width   : 500px;
      & > p
      {
        margin-bottom  : 8px !important;
        font-family    : AppleSDGothicNeoSB00;
        font-size      : 12px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 18px; /* 150% */
        letter-spacing : -0.36px;
        color          : #999
      }
      .notice_text_list
      {
        .notice_text_item
        {
          position       : relative;
          font-family    : AppleSDGothicNeoR00;
          font-size      : 12px;
          font-style     : normal;
          font-weight    : 400;
          line-height    : 18px; /* 150% */
          letter-spacing : -0.36px;
          color          : #999;
          padding-left   : 11px;
          &:after
          {
            content       : '';
            position      : absolute;
            top           : 10px;
            left          : 0;
            width         : 4px;
            height        : 4px;
            border-radius : 50%;
            background    : #999;
          }
          &:not(:last-child)
          {
            margin-bottom : 2px;
          }
        }
      }
    }
  }
}
</style>