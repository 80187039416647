<script>
import {useCounterStore} from '@/stores/counter'
import {useRouter} from 'vue-router'
import {onBeforeMount, onBeforeUnmount, reactive, ref, toRefs, watch} from "vue";
import AlarmHeaderVue from "@/components/common/AlarmHeaderVue.vue";
import MiddleLnbVue from "@/components/mainComponent/MiddleLnbVue.vue";
import FooterVue from "@/components/mainComponent/FooterVue.vue";
import LoadMianContentVue from "@/components/loadingComponents/LoadMianContentVue.vue";
import {useAuthStore} from "@/stores/useAuthStore";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import {useCustomerStep} from "@/stores/useCustomerStep";
import {useCustomerDataStore} from "@/stores/useCustomerDataStore";
import {useCustomSurvey} from "@/stores/customSurvey/useCustomSurvey";
import NavigationVue from "@Components/NavigationVue.vue";
import PopViewVue from "../components/common/PopViewVue.vue";
import ChannelTalkVue from "@Components/ChannelTalkVue.vue";
import {usePopSetStore} from "@/stores/usePopSetStore";


export default {
  name: 'MainVue',
  components: {ChannelTalkVue, PopViewVue, NavigationVue, SwiperSlide, Swiper, LoadMianContentVue, FooterVue, MiddleLnbVue, AlarmHeaderVue},
  setup () {
    const isOpenPop = usePopSetStore();
    const companyInfo = ref(null);
    const PopViewVue = ref(null);
    const survey = useCustomSurvey();
    const step = useCustomerStep();
    const customer = useCustomerDataStore();
    const timeOut = ref(String);
    const router = useRouter()
    const count = useCounterStore();
    const titles = ref('메인페이지');
    const state = reactive({
      isLoadData: false,
      swiperOption: {
        slidesPerView: '1',
        spaceBetween: '30',
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true
      },
      swiperObj: null,
      navigaTionBall: [
        {isActive: true},
        {isActive: false},
        {isActive: false}
      ],
      isPopOpne:false,
      modules: [Pagination, Autoplay],
      openAgreePopTitle: null,
      type_1_data: `<p>하루세탁 정기구독&nbsp;서비스 이용약관<br />
현행 시행일자: 2023년 00월 00일<br />
제1조. 목적<br />
이 약관은 주식회사 하루세탁(이하 &ldquo;회사&rdquo;라 합니다)가 회사가 운영하는 웹사이트, 모바일 웹 및 앱 등(이하 &ldquo;정기구독케어&rdquo;라 합니다)을 통해 제공하는 정기구독배송 정기구매 서비스를 이용하고자 하는 자와 회사 간의 권리, 의무, 서비스 이용절차 및 기타 제반사항을 정함에 그 목적이 있습니다.<br />
제2조. 정의<br />
① &ldquo;서비스&rdquo;란 회사가 회원의 편의를 위하여 약정기간동안 정기구독배송주기에 따라 상품을 정기적으로 결제 및 배송하는 서비스로, 회원이 지정한 내용(결제수단, 정기 결제일, 상품, 수량)대로 자동으로 결제하여 회원이 지정한 장소로 상품을 배송해주는 서비스를 의미합니다.<br />
② &ldquo;회원&rdquo;이란 정기구독케어 이용약관에 동의하고 회원등록을 한 자를 의미합니다.<br />
③ &ldquo;약정회원&rdquo;이란 서비스를 이용하기 위해 이 약관에 동의한 회원을 의미합니다.<br />
④ &ldquo;회차&rdquo;란 서비스를 통해 정기 결제가 이루어진 횟수를 의미합니다.<br />
⑤ &ldquo;약정기간&rdquo;이란 해당 서비스에서 정한 상품의 총 배송 기간을 의미합니다.<br />
⑥ &ldquo;정기구독배송주기&rdquo;란 서비스에 따라 정기 결제가 발생한 후 다음 번 정기 결제가 이루어지기까지의 기간을 의미합니다.<br />
제3조. 서비스의 가입<br />
① 서비스 이용계약은 회원이 정기구독케어에서 이 약관에 동의하고 서비스 가입을 신청하여, 회사가 이러한 신청에 승낙함으로써 체결됩니다.<br />
② 회원은 서비스 이용계약이 체결되는 즉시 약정회원이 됩니다.<br />
③ 회원은 서비스 가입 시 약정기간을 선택할 수 있습니다.<br />
④ 회사는 약정회원이 선택한 약정기간 동안 서비스를 유지하는 것을 조건으로 약정회원에게 혜택을 제공할 수 있습니다. 약정기간에 따라 약정회원이 받는 혜택이 달라질 수 있습니다. 약정회원은 자신이 받는 혜택을 서비스 가입화면 및 정기구독케어 내 마이페이지에서 확인할 수 있습니다.<br />
⑤ 회사는 약정회원이 선택한 약정기간 동안 서비스를 유지하는 것을 조건으로 할인가(이하 &ldquo;약정 할인가&rdquo;라 합니다)를 보장합니다.<br />
⑥ 서비스 신청이 완료된 후 해당 건에 대한 약정기간, 정기구독배송주기, 상품의 종류 및 수량의 변경은 불가하며, 약정회원은 서비스의 추가 신청을 통해 약정기간을 변경하거나, 상품의 종류나 수량을 추가할 수 있습니다.<br />
제4조. 결제<br />
① 회사는 약정회원이 지정한 결제수단으로 정기구독배송주기 마다 약정회원이 선택한 정기 결제일에 약정회원이 지정한 상품에 대하여 자동으로 결제합니다.<br />
② 지정한 정기 결제일에 정상적인 결제가 이루어지지 않는 경우, 회사는 정기 결제일 이후 토, 일, 공휴일을 제외하고 1영업일째 되는 날 및 2영업일째 되는 날 등 총 2회에 걸쳐 다시 결제를 시도할 수 있습니다. 그럼에도 불구하고 결제가 이루어지지 않을 경우 회사는 서비스를 해지할 수 있으며, 약정회원은 회사의 안내에 따라 회사에 약정 할인반환금을 지급하고, 혜택을 반환해야 합니다.<br />
③ 정기 결제일이 공휴일인 경우에는 직전 영업일에 결제를 진행하며, 2일 이상의 공휴일이 연속되는 연휴인 경우에는 약정회원에게 문자, 전화 등으로 별도로 통지한 후 안내한 날짜에 결제를 진행합니다.<br />
④ 약정회원은 약정기간 도중에 서비스에 대한 결제수단을 변경할 수 없습니다. 약정회원은 해당 약정기간의 마지막 정기 결제일 이후 다음 약정기간의 최초 정기 결제일 전까지 정기구독케어의 마이페이지를 통해 결제 수단을 변경할 수 있습니다.<br />
제5조. 상품의 중지 및 변경<br />
① 약정회원이 지정한 상품을 제공할 수 없는 일시적인 사유가 발생한 경우 회사는 약정회원에게 이러한 사실을 통지하고 해당 회차에 상품을 공급하지 않을 수 있습니다. 이 경우 회사는 결제를 일으키지 아니하며, 상품이 공급되지 않은 회차 동안 약정기간은 자동으로 연장됩니다.<br />
② 약정회원이 지정한 상품을 더 이상 판매할 수 없을 경우 회사는 약정회원의 다음 정기 결제일 30일 전까지 약정회원에게 이러한 사실을 통지하고 지정 상품을 대체할 수 있는 다른 상품으로 변경하여 배송할 수 있습니다. 약정회원은 해당 변경에 동의하지 않을 경우 서비스를 해지할 수 있으며, 이 때 약정 할인반환금 및 혜택 반환의무는 발생하지 않습니다.<br />
제6조. 종료 및 자동 재가입<br />
① 약정기간 종료 전까지 약정회원은 고객상담 번호 및 고객상담 게시판으로 서비스 종료의 의사표시를 할 수 있습니다. 이 경우 진행 중인 서비스는 약정기간 내 마지막 회차까지 진행 후 종료됩니다.<br />
② 약정회원이 약정기간 종료 전까지 서비스 종료의 의사표시를 하지 않을 경우 서비스는 약정회원이 지정한 내용(결제수단, 정기 결제일, 상품, 수량)과 동일한 내용으로 자동으로 재가입되며, 약정회원은 새로운 약정기간동안 서비스를 받을 수 있습니다.<br />
제7조. 해지 및 약정 할인반환금<br />
① 약정회원이 서비스를 해지하고자 하는 경우 회사에 서면, 고객상담 번호 및 고객상담 게시판으로 서비스의 해지를 요청해야 합니다.<br />
② 약정회원의 해지 요청이 정기 결제일로부터 3영업일 전에 접수된 경우에는 당해 회차부터, 위 기간을 초과하여 접수된 경우에는 다음 회차부터 서비스가 종료됩니다.<br />
③ 약정회원이 약정기간 종료 전 서비스를 해지하기 위해서는 본조 제4항 및 제5항에 따라 회사에 약정 할인반환금을 지급하고, 서비스의 가입 시 회사로부터 받은 혜택을 반환하여야 합니다.<br />
④ 약정회원이 약정기간 종료 전 서비스를 해지하는 경우, 당해 약정기간동안 이미 배송 받은 상품에 대한 약정 할인가 적용 혜택은 소급하여 소멸되며, 약정 할인반환금이 발생합니다. 약정 할인반환금은 상품의 정상가를 기준으로 약정 할인가와의 차액으로 산정되며, 약정 할인반환금은 약정회원의 해지 요청 후 약정 서비스 해지 전에 서비스 신청 시 등록한 결제수단으로 자동 결제됩니다.<br />
⑤ 약정회원이 해당 회차의 상품을 수령한 후 7일 이내에 서비스 해지를 요청한 경우, 약정회원은 당해 회차에 수령한 상품의 전부 또는 일부를 회사에 반환하고, 당해 회차에 수령한 상품 중 반환하지 않은 상품 및 이전 회차에 배송받은 상품에 대해서만 약정 할인반환금을 부담할 수 있습니다. 단, 이 때 회사에 반환하는 상품은 정기구독케어 이용약관에 따라 반품 및 교환이 불가능한 경우에 해당하지 않아야 합니다.<br />
⑥ 약정회원이 약정기간 종료 전 서비스를 해지하는 경우, 서비스의 가입 시 회사로부터 받은 혜택을 회사에게 반환해야 합니다. 혜택이 현금, 상품권인 경우 해당 금액을, 물품인 경우 회사에게 개봉 또는 사용하지 않은 상태의 물품을 반환하여야 하며, 이미 물품을 개봉한 경우 정기구독케어 내 해당 물품의 소비자가에 상당하는 금액을 반환해야 합니다.<br />
⑦ 약정회원이 서비스를 해지한 경우, 해지일로부터 6개월 간 모든 서비스의 재가입이 제한됩니다.<br />
제8조. 이용제한<br />
① 회사는 상품의 재판매 가능성이 있는 경우, 불법적이거나 부당한 행위와 관련된 경우 또는 약정회원이 회사의 이용약관을 위반하는 경우 약정회원의 서비스 이용을 제한할 수 있습니다.<br />
② 회사는 자체적인 시스템을 통한 모니터링과 각종 기관에서 접수된 민원 내용, 수사기관의 정보 등을 통해 정황을 확인한 후, 서비스 이용 제한 행위를 한 것으로 추정되는 경우 약정회원의 서비스 이용을 제한할 수 있습니다.<br />
제9조. 개인정보<br />
① 회사는 정기구독케어 이용약관 및 개인정보처리방침의 기재사항 이외에 약정회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다.<br />
② 회사는 수집하는 약정회원의 정보를 정기구독케어 이용약관, 개인정보처리방침 및 개인정보보호법 등 관련 법률이 정한 바에 따라 이용, 관리합니다.<br />
제10조. 약관의 효력 및 변경<br />
① 이 약관은 약정회원이 동의함으로써 효력이 발생합니다.<br />
② 이 약관에서 정하지 아니한 내용은 정기구독케어 이용약관에 따릅니다.<br />
③ 회사가 이 약관을 개정하는 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 7일 전부터 적용일 이후 상당한 기간 동안 정기구독케어에 공지합니다. 다만, 개정 내용이 약정회원에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 이를 정기구독케어에 공지하고, 약정회원의 문자 또는 카카오 알림톡으로 약관 개정 사실을 약정회원에게 개별 통지합니다. 약정회원의 휴대폰번호 변경 등으로 인하여 개별 통지가 어려운 경우, 약정회원이 등록한 휴대폰번호로 공지를 하였음에도 2회 이상 수신 불가한 경우 이 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다.<br />
④ 회사가 제3항의 공지 또는 통지를 하면서 개정약관 시행일 7일 후까지 약정회원이 거부의사를 표시하지 아니하면 약관의 개정에 동의한 것으로 간주한다는 내용을 명확하게 고지하였음에도 불구하고 약정회원이 명시적으로 약관 개정에 대한 거부의사를 표시하지 않는 경우, 회사는 약정회원이 개정약관 시행일자부로 개정약관에 동의한 것으로 간주합니다. 약정회원이 개정약관에 동의하지 않을 경우 서비스가 해지됩니다.<br />
⑤ 이 약관은 약정회원이 이 약관에 동의한 날로부터 서비스 종료시까지 적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 서비스가 종료한 후에도 유효하게 적용될 수 있습니다.<br />
⑥ 이 약관에 명시되지 아니한 사항과 이 약관에 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.<br />
&nbsp;</p>
`
    });
    const authInfo = useAuthStore();
    onBeforeMount(() => {
      step.reset();
      customer.resetCustomerData();
      timeOut.value = String(setTimeout(() => {
        state.isLoadData = true
      }, 500));
      console.log(authInfo.memberInfo);
      console.log(authInfo.memberToken);
      console.log(authInfo.memberRefreshToken);
    });
    onBeforeUnmount(() => {
      state.swiperObj = null;
      state.modules = null;
      window.location.reload()
    });
    watch(
        ()=>isOpenPop.isOpenPop,
        (newValue)=>{
          console.log('#############@@');
          console.log('#############@@');
          console.log('#############@@');
          console.log('#############@@');
          console.log("=>(MainVue.vue:130) newValue", newValue);
          console.log('#############@@');
          console.log('#############@@');
          console.log('#############@@');
          console.log('#############@@');
          state.isPopOpne = newValue;
        }
    )
    return {
      PopViewVue,
      companyInfo,
      survey,
      ...toRefs(state),
      count,
      router,
      handleIncrement () {
        count.increment()
      },
      // 메인 슬라이드 부분
      handleSlideChange () {
        for (let ball in state.navigaTionBall) {
          state.navigaTionBall.at(ball).isActive = false
        }
        // state.navigaTionBall.at(state.swiperObj.activeIndex).isActive = true;
      },
      handleOnSwiper (swiper) {
        state.swiperObj = swiper;
      },
      handleNaviClick (idx) {
        state.swiperObj.slideTo(idx);
        for (let ball in state.navigaTionBall) {
          state.navigaTionBall.at(ball).isActive = false
        }
        state.navigaTionBall.at(state.swiperObj.activeIndex).isActive = true;
      },
      handleCustomLink () {
        console.log('맞춤선택링크');
        // 맞춤구독 팦업
        survey.reset();
        step.resetTryOut();
        router.push('/customer')
      },
      // 체험해보기
      handleTryOut () {
        survey.updateTicketGubun('EXPERIENCE')
        step.updateTryOut(true);
        router.push(`/product/detail/15`);
      },
      handleLocation () {
        window.open('https://forms.gle/ayrLFTif97A1a6nX7', '_blank');
      },
      handleGetTitle (title) {
        console.log("-> title", title);
        state.openAgreePopTitle = title;
        PopViewVue.value.isShowPop();
      },
      handleCompanyInfo(){
        companyInfo.value.isShowPop();
      }
    }
  }
}
</script>

<template>
  <div v-if="isLoadData" class="main_wrapper">
    <div class="main_inner">
      <div class="main_header_con">
        <AlarmHeaderVue/>
      </div>
      <div class="main_content">
        <div class="main_top">
          <div class="position_box">
            <p class="notice_text"><strong class="notice_text st">밀착!</strong> 맞춤형 물세탁 하루세탁</p>
            <div class="middle_box">
              <swiper :modules="modules" :pagination="{ clickable: true }" class="mySwiper" navigation
                      v-bind="swiperOption"
                      @slideChange="handleSlideChange"
                      @swiper="handleOnSwiper">
                <swiper-slide class="swiper_content">
                  <p class="notice_text">맞춤 상품이 궁금하다면</p>
                  <h2 class="sub_title">나의 세탁 스타일에 맞는<br/>맞춤 세탁 상품 찾아보기!</h2>
                  <p class="notice_text_2">구독자 전용 <strong>타포린백</strong> 증정 🛍️</p>
                  <div class="image_con">
                    <img alt="" src="@/assets/icon/main/main_image_1.svg">
                  </div>
                  <div class="button_content">
                    <button class="custom_selection_btn" type="button" @click="handleCustomLink">
                      맞춤선택 링크
                      <img alt="" src="@/assets/icon/main/right_white_arrow.svg"/>
                      <span class="notice_balloon_text">
                      첫 결제 <strong>5,000원</strong> 할인!
                      <img alt="" class="arrow_icon" src="@/assets/icon/main/bottom_arrow.svg"/>
                    </span>
                    </button>
                  </div>
                </swiper-slide>
                <swiper-slide class="swiper_content">
                  <p class="notice_text">하루세탁이 궁금하다면</p>
                  <h2 class="sub_title">첫 고객이라면 9,900원에<br/>물세탁 체험 서비스를!</h2>
                  <p class="notice_text_2">첫 고객 물세탁 <strong>체험 이벤트</strong> 🎀</p>
                  <div class="image_con">
                    <img alt="" src="@/assets/icon/main/main_image_2.png">
                  </div>
                  <div class="button_content">
                    <button class="custom_selection_btn" type="button" @click="handleTryOut">
                      체험해보기
                      <img alt="" src="@/assets/icon/main/right_white_arrow.svg"/>
                    </button>
                  </div>
                </swiper-slide>
                <swiper-slide class="swiper_content">
                  <p class="notice_text">하루세탁 활동</p>
                  <h2 class="sub_title">독거노인과 취약계층을 위한<br/>하루세탁의 물세탁 서비스!</h2>
                  <p class="notice_text_3">세탁 도움이 필요하신 분들의 사연을 받아 선정<br/>
                    되시는 분께는 세탁 서비스 1회, 제안 주신 분께는<br/> 세탁 쿠폰 1만 원권을 드립니다.</p>
                  <div class="image_con three">
                    <img alt="" src="@/assets/icon/main/main_image_3.svg">
                  </div>
                  <div class="button_content">
                    <button class="custom_selection_btn" type="button" @click="handleLocation">
                      사연 보내기
                      <img alt="" src="@/assets/icon/main/right_white_arrow.svg"/>
                    </button>
                  </div>
                </swiper-slide>
              </swiper>
              <div id="my_paginato" slot="pagination" class="swiper-pagination"></div>
              <!--              <ul class="navigation_list">-->
              <!--                <li v-for="(ball,idx) in navigaTionBall" :key="idx" class="navigation_list_item">-->
              <!--                  <span :class="{active:ball.isActive}" class="navi" @click="handleNaviClick(idx)"></span>-->
              <!--                </li>-->
              <!--              </ul>-->
            </div>
          </div>
        </div>
        <div class="main_bottom">
          <MiddleLnbVue/>
        </div>
        <div class="footer">
          <FooterVue @termsTitle="handleGetTitle" @companyInfo="handleCompanyInfo"/>
        </div>
      </div>
    </div>
    <div>
    </div>
    <NavigationVue v-if="!isPopOpne"/>
  </div>
  <LoadMianContentVue v-if="!isLoadData"/>
  <PopViewVue ref="PopViewVue" :title="openAgreePopTitle">
    <div class="terms_wrap">
      <div class="terms_inner">
        <div class="terms_content">
          <h2 class="title">{{ openAgreePopTitle }}</h2>
          <div v-if="openAgreePopTitle" v-html="type_1_data"></div>
        </div>
      </div>
    </div>
  </PopViewVue>
  <PopViewVue ref="companyInfo" title="회사 소개">
    <div class="company_info_wrap">
      <div class="company_info_inner">
        <div class="image_con">
          <div class="image_area">
            <img src="@/assets/icon/company_info.png" alt="">
            <div class="company_icon_con">
              <img src="@/assets/icon/company_iocn.svg" alt="">
            </div>
          </div>
        </div>
        <div class="info_text_list">
          <div class="sect_1 sect">
            <h3 class="title">비대면 물세탁 전문</h3>
            <p class="info_text">커스텀세탁의 시작! 물세탁 전문 구독서비스
              지난 1년여간 마포구 지역에서 시작된 물세탁 전문서비스
              '하루세탁'은 이용해주신 고객분들의 성원에 힘입어 정식 론칭을 하게 되었습니다.
              더 나은 서비스로 보답하기 위해 노력하겠습니다.</p>
          </div>
          <div class="sect_2 sect">
            <h3 class="title">최단시간 특급세탁 서비스</h3>
            <p class="info_text">12시간 내에 세탁물을 수거, 배달합니다.
              지역기반으로 기술과 경험을 더한 특급세탁 서비스입니다.</p>
          </div>
          <div class="sect_3 sect">
            <h3 class="title">젊은 감각의 로컬 세탁브랜드</h3>
            <p class="info_text">하루에 행복과 긍정의 마음을 담아 세탁을 선물해요.
              매출의 1%는 지역사회 독거노인들을 위해 세탁기부합니다.
              환경과 지역사회를 생각하는 ESG경영을 추구합니다.</p>
          </div>
        </div>
      </div>
    </div>
  </PopViewVue>
  <ChannelTalkVue bottom="80" right="16"/>
</template>

<style lang="scss" scoped>
.company_info_wrap{
  padding-bottom: 40px;
  .company_info_inner{
    .image_con{
      text-align: center;
      margin-top: 8px;
      .image_area{
        display: inline-block;
        width: calc(100% - 48px);
        overflow: hidden;
        border-radius: 12px;
        position: relative;
        img{
          width: 100%;
        }
        .company_icon_con{
          position: absolute;
          top:63px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .info_text_list{
      margin-top: 16px;
      padding: 16px 24px;
      .sect{
        &:not(:last-child){
          margin-bottom: 32px;
        }
        .title{
          color: #4683FF;
          font-family: AppleSDGothicNeoB00;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.54px;
          margin-bottom: 10px;
        }
        .info_text{
          color: #000;
          font-family: AppleSDGothicNeoR00;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.42px;
        }
      }
    }
  }
}
.swiper-pagination-bullets.swiper-pagination-horizontal
{
  background : red;
}
.terms_wrap
{
  margin-top : 8px;
  .terms_inner
  {
    padding : 0 24px 24px 24px;
    .terms_content
    {
      border-radius : 12px;
      background    : #fff;
      padding       : 16px;
      .title
      {
        color          : #000;
        font-family    : AppleSDGothicNeoSB00;
        font-size      : 16px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 24px;
        letter-spacing : -0.48px;
        margin-bottom  : 8px;
      }
      div
      {
        color          : #666;
        font-family    : AppleSDGothicNeoL00;
        font-size      : 14px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 20px;
        letter-spacing : -0.42px;
      }
    }
  }
}
.main_wrapper
{
  font-size : 1.6em;
  .main_inner
  {
    .main_header_con
    {
      background : #BCD5FE;
    }
    .main_content
    {
      padding-bottom : 64px;
      .main_top
      {
        position      : relative;
        padding       : 0 24px;
        margin-bottom : 40px;
        .position_box
        {
          position : relative;
          z-index  : 20;
          .notice_text
          {
            padding-top    : 24px;
            font-family    : AppleSDGothicNeoR00;
            font-size      : 20px;
            font-style     : normal;
            font-weight    : 400;
            line-height    : 28px; /* 140% */
            letter-spacing : -0.8px;
            &.st
            {
              font-family    : AppleSDGothicNeoB00;
              font-size      : 20px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 28px;
              letter-spacing : -0.8px;
            }
          }
          .middle_box
          {
            overflow      : hidden;
            position      : relative;
            margin-top    : 16px;
            padding       : 32px 24px 32px 24px;
            background    : #fff;
            border-radius : 12px;
            min-height    : 444px;
            min-width     : 275px;
            z-index       : 10;
            .mySwiper
            {
              .swiper-pagination
              {
                .my_paginato
                {
                  bottom : -30px !important;
                }
              }
            }
            .swiper_content
            {
              .notice_text
              {
                color          : #4683FF;
                font-family    : AppleSDGothicNeoB00;
                font-size      : 14px;
                font-style     : normal;
                font-weight    : 400;
                line-height    : 20px;
                letter-spacing : -0.42px;
                margin-bottom  : 8px;
                padding-top    : unset;
              }
              .sub_title
              {
                color          : #000;
                font-family    : AppleSDGothicNeoEB00;
                font-size      : 22px;
                font-style     : normal;
                font-weight    : 400;
                line-height    : 28px;
                letter-spacing : -0.88px;
                margin-bottom  : 12px;
              }
              .notice_text_2
              {
                color          : #000;
                font-family    : AppleSDGothicNeoR00;
                font-size      : 14px;
                font-style     : normal;
                font-weight    : 400;
                line-height    : 20px; /* 142.857% */
                letter-spacing : -0.42px;
                strong
                {
                  color          : #000;
                  font-family    : AppleSDGothicNeoB00;
                  font-size      : 14px;
                  font-style     : normal;
                  font-weight    : 400;
                  line-height    : 20px;
                  letter-spacing : -0.42px;
                }
              }
              .notice_text_3
              {
                color          : #999;
                font-family    : AppleSDGothicNeoR00;
                font-size      : 14px;
                font-style     : normal;
                font-weight    : 400;
                line-height    : 20px;
                letter-spacing : -0.42px;
              }
              .image_con
              {
                text-align    : center;
                //margin: 20px 72.35px 32px 72.35px;
                margin-bottom : 32px;
                margin-top    : 20px;
                &.three
                {
                  margin-top    : 6px;
                  margin-bottom : 8px;
                }
              }
              .button_content
              {
                .custom_selection_btn
                {
                  display        : inline-block;
                  width          : 100%;
                  height         : 56px;
                  text-align     : center;
                  border-radius  : 8px;
                  background     : #222;
                  color          : #FFF;
                  font-family    : AppleSDGothicNeoB00;
                  font-size      : 16px;
                  font-style     : normal;
                  font-weight    : 400;
                  line-height    : 24px;
                  letter-spacing : -0.48px;
                  position       : relative;
                  .notice_balloon_text
                  {
                    position       : absolute;
                    top            : -14px;
                    right          : 62px;
                    border-radius  : 32px;
                    background     : #FAE100;
                    padding        : 2px 10px;
                    color          : var(--black, #000);
                    text-align     : center;
                    font-family    : AppleSDGothicNeoSB00;
                    font-size      : 12px;
                    font-style     : normal;
                    font-weight    : 400;
                    line-height    : 18px; /* 150% */
                    letter-spacing : -0.36px;
                    strong
                    {
                      color          : var(--black, #000);
                      font-family    : AppleSDGothicNeoEB00;
                      font-size      : 12px;
                      font-style     : normal;
                      font-weight    : 400;
                      line-height    : 18px;
                      letter-spacing : -0.36px;
                    }
                    .arrow_icon
                    {
                      position : absolute;
                      left     : 28px;
                      bottom   : -9px;
                    }
                  }
                }
              }
            }
            .navigation_list
            {
              display         : flex;
              justify-content : space-between;
              align-items     : center;
              width           : 30px;
              margin          : 0 auto;
              .navigation_list_item
              {
                .navi
                {
                  display       : inline-block;
                  width         : 6px;
                  height        : 6px;
                  background    : #D9D9D9;
                  border-radius : 50%;
                  &.active
                  {
                    background : #4683FF;
                  }
                }
              }
            }
          }
        }
        &:after
        {
          content    : '';
          position   : absolute;
          top        : 0;
          left       : 0;
          width      : 100%;
          height     : 283px;
          background : #BCD5FE;
        }
      }
      .footer
      {
        padding : 0 24px;
      }
    }
  }
}
</style>
