import axios from 'axios';
import {useAuthStore} from "@/stores/useAuthStore";

// const router = useRouter();
let tokenValue = null;

// axios 인스턴스 생성
const request = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    withCredentials: true
});
const request_2 = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    withCredentials: true
});

request.interceptors.request.use(
    function (config) {
        const auth = useAuthStore();
        tokenValue = `Bearer ${auth.memberToken}`;
        console.log('request config');
        console.log("-> tokenValue", tokenValue);
        config.headers.Authorization = tokenValue;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    function (response) {

        // refresh 토큰 검증 요청
        if (response.data.resultCode === -301) {
            handleTokenRefresh(response);
        }

        // refresh 토큰 만료 처리
        if (response.data.resultCode === -300) {
            location.href = '/auth/join';
        }
        if (response.data.resultCode === -200) {
            tostAlert(response.data.resultMessage);
        }

        return response;
    },
    function (error) {
        console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        console.error('----------------------------------------------------------');
        console.error('**********************************************************');
        console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        console.error("-> error", error.code);
        console.error(`이 부분에 에러가 났습니다 ${error.config.url}`);
        console.error(`request 데이타 ${error.config.data}`);
        console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        console.error('----------------------------------------------------------');
        console.error('**********************************************************');
        console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        return Promise.reject(error);
    }
);

function handleTokenRefresh (response) {
    const auth = useAuthStore();
    tokenValue = `Bearer ${auth.memberRefreshToken}`;
    response.config.headers.Authorization = `Bearer ${auth.memberRefreshToken}`;
    const apiUrl = `${import.meta.env.VITE_BASE_URL}/api/member/auth`;

    // 토큰 갱신 요청을 위한 새로운 axios 인스턴스 생성
    const refreshRequest = axios.create({
        baseURL: import.meta.env.VITE_BASE_URL,
        withCredentials: true
    });

    // 토큰 갱신 요청을 보냅니다.
    return refreshRequest({...response.config, url: apiUrl})
        .then(res => {
            const auth = useAuthStore();
            auth.setMemberToken(res.data.data.access_token, res.data.data.refresh_token);
            tokenValue = `Bearer ${store.getters.getToken}`;
            response.config.headers.Authorization = `Bearer ${store.getters.getToken}`;

            // 업데이트된 토큰을 사용하여 원래의 요청을 다시 시도합니다.
            return axios(response.config);
        })
        .catch(error => {
            // 토큰 갱신 요청에서 발생한 에러를 처리합니다.
            console.error(error);
            throw new Error(error);
        });
}

export default request;