<script>
import {getCurrentInstance, onBeforeMount, onMounted, reactive, toRefs, watch} from "vue";
import TypeTwoHeaderVue from "@/components/common/TypeTwoHeaderVue.vue";
import {useWindowSize} from "@vueuse/core";
import {usePopSetStore} from "@/stores/usePopSetStore";


export default {
  name: "PopViewVue",
  components: {TypeTwoHeaderVue},
  props: ['title'],
  setup () {
    const isOpenPop = usePopSetStore();
    const { height } = useWindowSize();
    const {props, emit} = getCurrentInstance();
    const state = reactive({
      isPopOpen: false,
      setHeight: 0,
    });
    const isShowPop = () => {
      state.isPopOpen = true;
      emit('open');
    }
    const isHidePop = () => {
      emit('close');
      state.isPopOpen = false;
    }
    onBeforeMount(() => {
      let doc = document.getElementsByTagName('html');
      console.log(doc);
      doc[0].style.overflow = 'unset';
    });
    watch(
        () => state.isPopOpen,
        (newValue) => {
          if (newValue) {
            let doc = document.getElementsByTagName('html');
            console.log(doc);
            doc[0].style.overflow = 'hidden';
          } else {
            isOpenPop.setIsOpenPop(false)
            let doc = document.getElementsByTagName('html');
            console.log(doc);
            doc[0].style.overflow = 'unset';
          }
        }
    );

    return {
      ...toRefs(state),
      props,
      isShowPop,
      isHidePop
    }
  }
}
</script>

<template>
  <div :class="{'show-animation': isPopOpen }" class="pop_view_wrapper">
    <div class="pop_view_inner">
      <TypeTwoHeaderVue :title='props.title' @closePopView="isHidePop()"/>
      <div class="pop_view_content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:root{
  --vh:0;
}
.pop_view_wrapper
{
  position   : fixed;
  top:0;
  left       : 50%;
  width      : 100%;
  background : #f2f3f7;
  max-width  : 500px;
  //z-index: 999;
  transform  : scale(1) translateX(-50%) translateY(200%);
  transition : transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
  color      : #000;
  height: 100%;
  overflow   : auto;
  z-index    : 2000!important;
  &.show-animation
  {
    transform : scale(1) translateX(-50%) translateY(0%);
  }
  .pop_view_inner
  {
    .pop_view_content
    {
      height : 100%;
    }
  }
}
</style>