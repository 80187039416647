<script>
export default {
  name: "LoadingMyRevoewVue"
}
</script>

<template>
  <div class="loadding">
    <q-card class="col" flat>
      <q-skeleton height="156px" square />
      <q-card-section>
        <q-skeleton class="text-subtitle1" type="text" />
        <q-skeleton class="text-subtitle1" type="text" width="50%" />
        <q-skeleton class="text-caption" type="text" />
      </q-card-section>
    </q-card>
  </div>
  <div class="loadding">
    <q-card class="col" flat>
      <q-skeleton height="156px" square />
      <q-card-section>
        <q-skeleton class="text-subtitle1" type="text" />
        <q-skeleton class="text-subtitle1" type="text" width="50%" />
        <q-skeleton class="text-caption" type="text" />
      </q-card-section>
    </q-card>
  </div>
  <div class="loadding">
    <q-card class="col" flat>
      <q-skeleton height="156px" square />
      <q-card-section>
        <q-skeleton class="text-subtitle1" type="text" />
        <q-skeleton class="text-subtitle1" type="text" width="50%" />
        <q-skeleton class="text-caption" type="text" />
      </q-card-section>
    </q-card>
  </div>
  <div class="loadding">
    <q-card class="col" flat>
      <q-skeleton height="156px" square />
      <q-card-section>
        <q-skeleton class="text-subtitle1" type="text" />
        <q-skeleton class="text-subtitle1" type="text" width="50%" />
        <q-skeleton class="text-caption" type="text" />
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="scss" scoped>
.loadding{
  &:not(:last-child){
    margin-bottom: 10px;
  }
}
</style>