<script>
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useRouter} from "vue-router";


export default {
  name: "AlarmVue",
  props: ['newAlarmCheck'],
  setup () {
    const {props} = getCurrentInstance();
    const router = useRouter();
    const state = reactive({});
    return {
      props,
      router,
      ...toRefs(state),
      handleLocation () {
        console.log('알람');
        router.push('/alarm');
      }
    }
  }
}
</script>

<template>
  <button :class="{active: props.newAlarmCheck}" class="alarm" type="button" @click="handleLocation">
    <img alt="" src="@/assets/icon/common/alarm_icon.svg"/>
  </button>
</template>

<style lang="scss" scoped>
.alarm
{
  display  : inline-block;
  width    : 16px;
  height   : 18px;
  position : relative;
  &.active
  {
    &:after
    {
      content       : '';
      position      : absolute;
      top           : 0;
      right         : 0;
      width         : 4px;
      height        : 4px;
      background    : #F33B6E;
      border-radius : 50%;
    }
  }
  img
  {
    width : 100%;
  }
}
</style>