import axios from "@/axios/index";


export default {
    alarmList (param) {
        return axios.post('/api/common/alarmLogList', param);
    },
    notificationReadProcessing (param) {
        return axios.post('/api/common/alarmLogRead', param);
    },
    deleteAllNotifications (param) {
        return axios.post('/api/common/alarmLogDelete', param);
    }
}