import {defineStore} from 'pinia'

export const usePopSetStore = defineStore('usePopSet', {
    state: () => {
        return {
            isOpenPop: false
        }
    },
    actions: {
        setIsOpenPop(data){
            this.isOpenPop = data
        }
    },
    getters: {},
    persist: false
})
