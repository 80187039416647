import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {createPersistedState} from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import {Loading, Notify, Quasar} from 'quasar'
import '@quasar/extras/material-icons/material-icons.css'
import {createBottomSheet} from 'bottom-sheet-vue3'
// import 'bottom-sheet-vue3/style.css'
// Import Quasar css
import 'quasar/src/css/index.sass'
import SecureLS from "secure-ls";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import VueDaumPostcode from 'vue-daum-postcode'
import {defaultConfig, plugin} from '@formkit/vue'
import './assets/main.css'


const app = createApp(App)
const pinia = createPinia()
let ls = new SecureLS({isCompression: true});
pinia.use(createPersistedState({
    storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
    }
}))
app.component('infinite-loading', InfiniteLoading)
app.use(pinia)

app.use(router)
app.use(Quasar, {
    plugins: {
        Loading,
        Notify,
    },
    animations: 'all'
})
app.use(createBottomSheet());
app.use(VueDaumPostcode);
app.use(plugin, defaultConfig);
app.mount('#app')
