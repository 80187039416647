export default {
    path: '/myCard',
    children: [
        {
            path: '',
            name: 'mycard',
            label: '나의카드',
            component: () => import('@/views/myCard/MyCardVue.vue'),
            meta:{
                title:'하루세탁'
            }
        },
    ]
}
