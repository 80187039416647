import {defineStore} from 'pinia'

export const useAuthStore = defineStore('authStore', {
    state: () => {
        return {
            memberInfo: null,
            memberToken: null,
            memberRefreshToken: null,
            memberRegisterValue: {
                email: null,
                password: null,
                isRegisterSuc: null,
                userName: null,
                userPhonNumber: null,
                userSex: null,
                userCi: null,
                userDi: null,
                mem_birthday: null,
            },
            isRegisterSuc: null,
            loginInfo: {
                userInputEmail: null,
                userInputPwd: null
            },
            emailVerification: false,
            emailRemember: null,
            changePwMemberSeq: null,
        }
    },
    actions: {
        resetRemember() {

        },
        updateChangePwMemberSeq(data) {
            this.changePwMemberSeq = data;
        },
        resetChangePwMemberSeq() {
            this.changePwMemberSeq = null;
        },
        setMemberToken(memberToken, memberRefreshToken) {
            this.memberToken = memberToken;
            this.memberRefreshToken = memberRefreshToken;
        },
        updateMemberInfo(newMemberInfo) {
            // 유저 정보 업데이트
            this.memberInfo = newMemberInfo
        },
        setRegisterEmailValu(email) {
            // 유저 이메일 값
            this.memberRegisterValue.email = email
        },
        setRegisterPasswordValue(password) {
            // 유저 비밀번호 값
            this.memberRegisterValue.password = password
        },
        setIdentityVerificationUpdate(userName, userPhonNumber, userSex, userCi, userDi, mem_birthday) {
            this.memberRegisterValue.userName = userName;
            this.memberRegisterValue.userPhonNumber = userPhonNumber;
            this.memberRegisterValue.userSex = userSex;
            this.memberRegisterValue.userCi = userCi;
            this.memberRegisterValue.userDi = userDi;
            this.memberRegisterValue.mem_birthday = mem_birthday;
        },
        setIsemailVerification(result) {
            this.emailVerification = result;
        },
        setIsRegisterSet(isSet) {
            // 회원가입 성공 여부
            this.isRegisterSuc = isSet
        },
        reset() {
            this.memberInfo = ''
        },
        resetRegistrationValue() {
            // 회원가입시 유저 입력 정보
            this.memberRegisterValue.email = null;
            this.memberRegisterValue.password = null;
            this.memberRegisterValue.isRegisterSuc = null;
            this.emailVerification = false;
            this.memberRegisterValue.userName = null;
            this.memberRegisterValue.userPhonNumber = null;
            this.memberRegisterValue.userSex = null;
            this.memberRegisterValue.userCi = null;
            this.memberRegisterValue.userDi = null;
            this.memberRegisterValue.mem_birthday = null;
            this.emailRemember = null;
            this.changePwMemberSeq = null;
        },
        setLoginInfoSetUserEmail(email) {
            this.loginInfo.userInputEmail = email;
        },
        loginInfoReset() {
            // 로그인 시 유저 이메일 기억용
            this.loginInfo.userInputEmail = null;
            this.loginInfo.userInputPwd = null;
        },
        //이메일값 리셋
        resetUserRegister() {
            this.memberRegisterValue.email = null;
        },
        logOutFuc() {
            this.memberInfo = null;
            this.memberToken = null;
            this.memberRefreshToken = null;
        },
        updateEmailRemember(data) {
            this.emailRemember = data;
        },
        resetEmailRemember() {
            this.emailRemember = null;
        }
    },
    getters: {},
    persist: true
})
