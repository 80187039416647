<script>
import {nextTick, reactive, ref, toRefs} from "vue";
import AlarmHeaderVue from "@Components/common/AlarmHeaderVue.vue";
import {useRouter} from "vue-router";
import PopViewVue from "@Components/common/PopViewVue.vue";
import DryTipVue from "@views/dryTip/DryTipVue.vue";
import CouponVue from "@views/coupon/CouponVue.vue";
import InfiniteLoading from "v3-infinite-loading";
import {ReviewApiFuc} from "@/api/ReviewApi/ReviewRequestApi";
import LoadingMyRevoewVue from "@Components/loadingComponents/LoadingMyRevoewVue.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {maskString} from "@/utill/maskingFuc";
import {usePopSetStore} from "@/stores/usePopSetStore";


export default {
  name: "MiddleLnbVue",
  components: {SwiperSlide, Swiper, LoadingMyRevoewVue, InfiniteLoading, CouponVue, DryTipVue, PopViewVue, AlarmHeaderVue},
  methods: {maskString},
  setup () {

    const PopViewVueTypeTwo = ref(null); // 나의 쿠폰 팝업
    const PopViewVue = ref(null); // 나의 세탁 노하우 팝업
    const PopViewReviewVue = ref(null); // 나의 세탁 노하우 팝업
    const isOpenPop = usePopSetStore();
    const state = reactive({
      isPopOpen: false,
      isCouPonPop: false,
      reviewPop: false,
      listOfAvailableReviews: [],
      pages: 0,
      LoadMyReview: false,
      reviewListArray: [],
      isMore: false,
    });

    const router = useRouter();
    const handleReLoadReviewList = () => {
      console.log("-> state.count", state.count);
      console.log("-> state.reviewListArray", state.reviewListArray);
      if (Number(state.reviewListArray.length) < Number(state.count)) {
        console.log('########');
        isLoadList();
      }
    }
    const isLoadList = () => {
      state.pages++;
      nextTick(() => {
        ReviewApiFuc.ReviewListRequest({
          page: state.pages
        }).then(res => {
          const el = document.getElementById('app');
          const elWidth = el.offsetWidth;
          const baseWidth = 500; // Base width for comparison
          const baseTextLength = 65; // Base text length
          const newTextLength = Math.round((elWidth / baseWidth) * baseTextLength);

          if (state.reviewListArray && state.reviewListArray.length > 0) {
            state.reviewListArray = state.reviewListArray.map((item) => {
              let images = [];
              if (item.review_file1) images.push(item.review_file1);
              if (item.review_file2) images.push(item.review_file2);

              return {
                ...item,
                images: images,
                imageToTalCount: images.length,
                imageIsCount: 1,
                more: false,
                textCharacterLength: newTextLength - 5,
              }
            });
          }
          console.log('@@@@@@@@@');
          console.log(el);
          console.log(newTextLength)

          console.log(res);
          state.count = res.data.count;
          if (Object.keys(res.data).includes('result')) {
            state.reviewListArray.push(...res.data.result);
          } else {
            state.reviewListArray = res.data;
          }
          if (state.reviewListArray && state.reviewListArray.length > 0) {
            state.reviewListArray = state.reviewListArray.map((item) => {
              let images = [];
              if (item.review_file1) images.push(item.review_file1);
              if (item.review_file2) images.push(item.review_file2);

              return {
                ...item,
                images: images,
                imageToTalCount: images.length,
                imageIsCount: 1,
                more: false,
                textCharacterLength: newTextLength - 5,
              }
            });
          }
          state.LoadMyReview = true;
        });
      });
    }
    return {
      isLoadList,
      handleReLoadReviewList,
      PopViewVueTypeTwo,
      PopViewVue,
      PopViewReviewVue,
      router,
      ...toRefs(state),
      handleReviewLocation () {
        isLoadList()
        PopViewReviewVue.value.isShowPop();
        // router.push('/myactivity/review');
        state.reviewPop = true;
        isOpenPop.setIsOpenPop(state.reviewPop);
        window.addEventListener('resize', (data) => {
          const el = document.getElementById('app');
          const elWidth = el.offsetWidth;
          const baseWidth = 500; // Base width for comparison
          const baseTextLength = 65; // Base text length

          // Calculate the new textCharacterLength based on the ratio of elWidth to baseWidth
          const newTextLength = Math.round((elWidth / baseWidth) * baseTextLength);
          if (state.reviewListArray && state.reviewListArray.length > 0) {
            state.reviewListArray = state.reviewListArray.map((item) => {
              let images = [];
              if (item.review_file1) images.push(item.review_file1);
              if (item.review_file2) images.push(item.review_file2);

              return {
                ...item,
                images: images,
                imageToTalCount: images.length,
                imageIsCount: 1,
                more: false,
                textCharacterLength: newTextLength - 5,
              }
            });
          }
        })
      },
      handleDryTip () {
        // router.push('/dryTip');
        state.isCouPonPop = false;
        state.isPopOpen = true;
        PopViewVue.value.isShowPop();
        PopViewVueTypeTwo.value.isHidePop();
      },
      handleCouPonPop () {
        state.isPopOpen = false;
        state.isCouPonPop = true;
        PopViewVue.value.isHidePop();
        PopViewVueTypeTwo.value.isShowPop();
      },
      swiperOption: {
        slidesPerView: '1',
      },
      handleOnSwiper (swiper) {
        console.log(swiper)
      },
      handleSlideChange (changeData) {
        changeData.imageIsCount === 1 ? changeData.imageIsCount = 2 : changeData.imageIsCount = 1;
      },
      handleMoreText (reviewItem) {
        reviewItem.more = true;
        reviewItem.textCharacterLength = 999999;
        // state.isMore = true;
      },
    }
  }
}
</script>

<template>
  <div class="middle_lnb_wrap">
    <div class="middle_lnb_inner">
      <h3 class="middle_title">
        <strong>하루세탁</strong> 알차게 이용하기
      </h3>
      <ul class="lnb_list">
        <li class="lnb_list_item" @click="handleDryTip">
          <div class="left">
            <div class="image_con">
              <img alt="" src="@/assets/icon/mainLnbImage/lnb_image_1.svg"/>
            </div>
          </div>
          <div class="right">
            <div class="text_con">
              <p class="notice_text">내 옷은 어떻게 세탁될까?</p>
              <h3 class="sub_title">맞춤형 세탁진행 노하우</h3>
            </div>
            <div class="arrow_con">
              <img alt="" src="@/assets/icon/category/gray_left_arrow_icon.svg">
            </div>
          </div>
        </li>
        <li class="lnb_list_item" @click="handleCouPonPop">
          <div class="left">
            <div class="image_con">
              <img alt="" src="@/assets/icon/mainLnbImage/lnb_image_2.svg"/>
            </div>
          </div>
          <div class="right">
            <div class="text_con">
              <p class="notice_text">한 눈에 보는 혜택 꾸러미!</p>
              <h3 class="sub_title">놓치기엔 아까운 할인쿠폰</h3>
            </div>
            <div class="arrow_con">
              <img alt="" src="@/assets/icon/category/gray_left_arrow_icon.svg">
            </div>
          </div>
        </li>
        <li class="lnb_list_item" @click="handleReviewLocation">
          <div class="left">
            <div class="image_con">
              <img alt="" src="@/assets/icon/mainLnbImage/lnb_image_3.svg"/>
            </div>
          </div>
          <div class="right">
            <div class="text_con">
              <p class="notice_text">생생 사용 후기! 솔직 리뷰!</p>
              <h3 class="sub_title">후기로 보는 하루세탁</h3>
            </div>
            <div class="arrow_con">
              <img alt="" src="@/assets/icon/category/gray_left_arrow_icon.svg">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <PopViewVue ref="PopViewVue" title="세탁 노하우" @close="isPopOpen = false">
      <DryTipVue v-if="isPopOpen"/>
    </PopViewVue>
    <PopViewVue ref="PopViewVueTypeTwo" title="할인쿠폰" @close="isCouPonPop = false">
      <CouponVue v-if="isCouPonPop"/>
    </PopViewVue>
    <PopViewVue ref="PopViewReviewVue" title="고객 후기" @close="reviewPop = false" :reviewPop="reviewPop">
      <div v-if="reviewPop" class="my_review_list_wrap">
        <div class="my_review_list_inner">
          <ul class="my_review_list">
            <li v-if="!LoadMyReview" class="my_review-list_item">
              <LoadingMyRevoewVue/>
            </li>
            <li v-for="(review_list,idx) in reviewListArray" v-if="LoadMyReview && reviewListArray.length > 0"
                :key="idx"
                class="my_review-list_item">
              <div v-if="review_list.images.length > 0" class="image_con">
                <swiper class="mySwiper" v-bind="swiperOption" @slideChange="handleSlideChange(review_list)"
                        @swiper="handleOnSwiper">
                  <swiper-slide v-for="(images,image_idx) in review_list.images" :key="image_idx"
                                class="swiper_content">
                    <img :src="images"/>
                  </swiper-slide>
                </swiper>
                <div class="image_navi">
                  <span class="count_txt image_count">{{ review_list.imageIsCount }}</span> /
                  <span class="count_txt image_total_count">{{ review_list.imageToTalCount }}</span>
                </div>
              </div>
              <div :class="{more:review_list.more}" class="text_con">
                <div class="top">
                  <h3 class="title_con">{{ review_list.goods_name ? review_list.goods_name : '상품이름 없음' }}<span
                      v-if="review_list.payment_cnt" class="subscrypt_txt"> &middot; 구독 {{ review_list.payment_cnt
                    }}회차</span>
                  </h3>
                  <p class="user_name_con">{{ maskString(review_list.mem_name) }}</p>
                </div>
                <div class="position_box">
                  <div
                      :class="{more:review_list.more, is_more:review_list.review_content.length > review_list.textCharacterLength}"
                      class="review_content_con">
                    {{ review_list.review_content.substring(0, review_list.textCharacterLength) }}
                    <button v-if="review_list.review_content.length > review_list.textCharacterLength"
                            v-show="!review_list.more" class="more_btn"
                            @click="handleMoreText(review_list)">...더보기
                    </button>
                  </div>

                </div>
              </div>
            </li>
            <li v-else class="my_review-list_item info_list">
              작성한 후기가 없습니다.
            </li>
            <infinite-loading @infinite="handleReLoadReviewList"/>
          </ul>
        </div>
      </div>
    </PopViewVue>
  </div>
</template>

<style lang="scss" scoped>
.my_review_list_wrap
{
  padding : 0 24px 104px 24px;
  .my_review_list_inner
  {
    .my_review_list
    {
      .my_review-list_item
      {
        border-radius : 12px;
        overflow      : hidden;
        &.info_list
        {
          text-align : center;
          font-size  : 14px;
        }
        &:not(:last-child)
        {
          margin-bottom : 10px;
        }
        .image_con
        {
          overflow   : hidden;
          position   : relative;
          //padding-top: 50%;
          //height: 156px;
          background : #D4D4D4;
          .swiper_content
          {
            position    : relative;
            padding-top : 50%;
            &:after
            {
              content    : '';
              position   : absolute;
              top        : 0;
              left       : 0;
              width      : 100%;
              height     : 100%;
              background : rgba(0, 0, 0, 0.02);
            }
            img
            {
              position   : absolute;
              top        : 50%;
              left       : 50%;
              transform  : translate(-50%, -50%);
              width      : 100%;
              object-fit : cover;
            }
          }
          .image_navi
          {
            padding       : 4px 12px;
            position      : absolute;
            bottom        : 10px;
            left          : 50%;
            z-index       : 100;
            transform     : translateX(-50%);
            border-radius : 16px;
            background    : rgba(0, 0, 0, 0.30);
            color         : #FFF;
            text-align    : center;
            font-family   : AppleSDGothicNeoM00;
            font-size     : 10px;
            font-style    : normal;
            font-weight   : 400;
            line-height   : 14px;
          }
        }
        .text_con
        {
          position       : relative;
          //height     : 110px;
          padding-bottom : 16px;
          background     : #fff;
          .position_box
          {
            position : relative;
            .more_btn
            {
              font-family    : AppleSDGothicNeoM00;
              font-size      : 12px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 18px;
              letter-spacing : -0.36px;
              color          : #999;
            }
          }
          &.more
          {
            height         : auto;
            padding-bottom : 16px;
          }
          .top
          {
            display         : flex;
            justify-content : space-between;
            align-items     : center;
            padding         : 16px;
            .title_con
            {
              font-family    : AppleSDGothicNeoSB00;
              font-size      : 16px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 24px; /* 150% */
              letter-spacing : -0.48px;
              color          : #333;
              .subscrypt_txt
              {
                color          : #999;
                font-family    : AppleSDGothicNeoM00;
                font-size      : 12px;
                font-style     : normal;
                font-weight    : 400;
                line-height    : 18px;
                letter-spacing : -0.36px;
              }
            }
            .user_name_con
            {
              font-family    : AppleSDGothicNeoL00;
              font-size      : 14px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 20px; /* 142.857% */
              letter-spacing : -0.42px;
              color          : #666;
            }
          }
          .review_content_con
          {
            position       : relative;
            //padding            : 0 16px;
            display        : -webkit-box;
            color          : var(--gray70, #333);
            font-family    : AppleSDGothicNeoL00;
            font-size      : 14px;
            font-style     : normal;
            font-weight    : 400;
            line-height    : 20px; /* 142.857% */
            letter-spacing : -0.42px;
            padding-right  : 16px;
            padding-left   : 16px;
            overflow       : hidden;
            text-overflow  : ellipsis;
            &.is_more
            {
              padding            : 0 16px;
              -webkit-box-orient : vertical;
              -webkit-line-clamp : 2;
              overflow           : hidden;
              text-overflow      : ellipsis;
              height             : 40px;
            }
            &.more
            {
              height             : auto;
              -webkit-line-clamp : unset;
            }
          }
        }
      }
    }
  }
}
.middle_lnb_wrap
{
  &:deep
  {
    .pop_view_wrapper
    {
      z-index : 2000;
    }
  }
  padding    : 0 24px;
  margin-top : 40px;
  .middle_lnb_inner
  {
    .middle_title
    {
      color          : #000;
      font-family    : AppleSDGothicNeoM00;
      font-size      : 20px;
      font-style     : normal;
      font-weight    : 400;
      line-height    : 28px;
      letter-spacing : -0.8px;
      strong
      {
        font-family    : AppleSDGothicNeoEB00;
        font-size      : 20px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 28px; /* 140% */
        letter-spacing : -0.8px;
      }
    }
    .lnb_list
    {
      margin-top    : 32px;
      margin-bottom : 56px;
      .lnb_list_item
      {
        cursor          : pointer;
        &:not(:last-child)
        {
          margin-bottom : 32px;
        }
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        .left
        {
          margin-right : 16px;
          .image_con
          {
            width         : 42px;
            height        : 42px;
            border-radius : 50%;
            margin-right  : auto;
            img
            {
              width : 100%;
            }
          }
        }
        .right
        {
          display         : flex;
          justify-content : space-between;
          align-items     : center;
          width           : calc(100% - 58px);
          .text_con
          {
            width : calc(100% - 16px);
            .notice_text
            {
              color          : #4683FF;
              font-family    : AppleSDGothicNeoM00;
              font-size      : 12px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 18px; /* 150% */
              letter-spacing : -0.36px;
            }
            .sub_title
            {
              color          : #000;
              font-family    : AppleSDGothicNeoSB00;
              font-size      : 16px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 24px; /* 150% */
              letter-spacing : -0.48px;
            }
          }
          .arrow_con
          {
            width  : 16px;
            height : 16px;
            img
            {
              width : 100%;
            }
          }
        }
      }
    }
  }
}
</style>