import alarmapi from "@/api/alarmapi/alarmapi";


/**
 * 알림리스트 api
 */
export class AlarmApiRequest {
    static async alarmListRequest (param) {
        console.log("-> param", param);
        try {

            const data = await alarmapi.alarmList(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * 알림 읽음처리
     * @param param
     * @returns {Promise<*>}
     */
    static async notificationReadProcessingRequest (param) {
        try {
            const data = await alarmapi.notificationReadProcessing(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }

    /**
     * 알람 전체삭제
     * @param param
     * @returns {Promise<*>}
     */
    static async deleteAllNotificationsRequest (param) {
        try {
            const data = await alarmapi.deleteAllNotifications(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }
}