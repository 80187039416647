<script>
import {reactive, toRefs} from "vue";
import {useRouter} from "vue-router";
import {useCustomSurvey} from "@/stores/customSurvey/useCustomSurvey";
import {useCustomerStep} from "@/stores/useCustomerStep";


export default {
  name: "NavigationVue",
  setup () {
    const survey = useCustomSurvey();
    const step = useCustomerStep();
    const router = useRouter();
    const state = reactive({});
    return {
      router,
      ...toRefs(state),
      handleLocation (path) {
        if (path && path !== 'CUSTOMER') {
          router.push(path);
        } else {
          survey.reset();
          step.resetTryOut();
          router.push('/customer')
        }

      }
    }
  }
}
</script>

<template>
  <div class="navigation_wrap">
    <div class="navigation_inner">
      <ul class="navigation_list">
        <li class="navigation_item">
          <button type="button" @click="handleLocation('/category')">
            <div class="icon_tag">
              <img alt="카테고리" src="@/assets/icon/navigation_icon/category.svg"/>
            </div>
            <p class="navi_text">카테고리</p>
          </button>
        </li>
        <li class="navigation_item">
          <button type="button" @click="handleLocation('CUSTOMER')">
            <div class="icon_tag">
              <img alt="구독신청" src="@/assets/icon/navigation_icon/subscribe.svg"/>
            </div>
            <p class="navi_text">구독신청</p>
          </button>
        </li>
        <li class="navigation_item">
          <button type="button" @click="handleLocation('/mypage/subscribe')">
            <div class="icon_tag">
              <img alt="이용내역" src="@/assets/icon/navigation_icon/usage.svg"/>
            </div>
            <p class="navi_text">나의세탁</p>
          </button>
        </li>
        <li class="navigation_item">
          <button type="button" @click="handleLocation('/mypage')">
            <div class="icon_tag">
              <img alt="마이" src="@/assets/icon/navigation_icon/my.svg"/>
            </div>
            <p class="navi_text">마이</p>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation_wrap
{
  position   : fixed;
  bottom     : 0;
  left       : 50%;
  transform  : translateX(-50%);
  max-width  : 500px;
  width      : 100%;
  height     : 64px;
  box-shadow : 0px -4px 16px 0px rgba(0, 0, 0, 0.10);
  z-index    : 999;
  background : #F2F3F7;
  .navigation_inner
  {
    height : 100%;
    .navigation_list
    {
      display         : flex;
      justify-content : space-between;
      align-items     : center;
      height          : 100%;
      padding         : 13px 32px;
      .navigation_item
      {
        button
        {
          cursor     : pointer;
          text-align : center;
          .icon_tag
          {
            display : inline-block;
            width   : 24px;
            height  : 24px;
          }
          .navi_text
          {
            margin-top  : 2px;
            color: #666;
            text-align: center;
            font-family: AppleSDGothicNeoM00;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>