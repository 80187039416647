<script setup>

import {useRouter} from "vue-router";
import {reactive} from "vue";
import NavigationVue from "@Components/NavigationVue.vue";


const router = useRouter();
const state = reactive({
  tipList: [
    {
      title: 'Step 1. 단독세탁',
      subList: ['직접하는 세탁처럼 내 집만 단독 진행', '원하는 세제 선택', '색상 분리세탁도 가능 (이염 방지시트 기본)']
    },
    {
      title: 'Step 2. 저온건조',
      subList: ['형태변형 및 수축 최소화', '가스식 열풍건조']
    },
    {
      title: 'Step 3. 맞춤향기',
      subList: ['취향에 맞는 드라이시트향 선택',]
    },
    {
      title: 'Step 4. 안심폴딩',
      subList: ['여성속옷 안심폴딩 시스템 (여성 속옷은 여성분이 케어)', '속옷 이중포장으로 프라이버시 보호']
    },
  ]
})
</script>

<template>
  <div class="dry_tip_wrap">
    <div class="dry_tip_inner">
      <div class="dry_tip_content">
        <ul class="dry_tip_list">
          <li v-for="(tip,idx) in state.tipList" :key="idx" class="dry_tip_list_item">
            <h3 class="sub_title">{{ tip.title }}</h3>
            <ul class="sub_tip_list">
              <li v-for="(subTip,index) in tip.subList" :key="index" class="sub_tip_list_item">{{ subTip }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <NavigationVue/>
  </div>
</template>

<style lang="scss" scoped>
.dry_tip_wrap
{
  max-width : 500px;
  margin    : 0 auto;
  .dry_tip_inner
  {
    .dry_tip_content
    {
      padding    : 0 24px;
      margin-top : 24px;
      .dry_tip_list
      {
        .dry_tip_list_item
        {
          border-radius : 12px;
          background    : #fff;
          padding       : 16px;
          &:not(:last-child)
          {
            margin-bottom : 10px;
          }
          .sub_title
          {
            color          : #000;
            font-family    : AppleSDGothicNeoSB00;
            font-size      : 16px;
            font-style     : normal;
            font-weight    : 400;
            line-height    : 24px;
            letter-spacing : -0.48px;
          }
          .sub_tip_list
          {
            margin-top : 8px;
            .sub_tip_list_item
            {
              color          : #333;
              font-family    : AppleSDGothicNeoL00;
              font-size      : 14px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 20px;
              letter-spacing : -0.42px;
              &:not(:last-child)
              {
                margin-bottom : 4px;
              }
              &:before
              {
                content       : '';
                position      : relative;
                top           : -3px;
                display       : inline-block;
                width         : 4px;
                height        : 4px;
                background    : #ddd;
                border-radius : 50%;
                margin-right  : 6px;
              }
            }
          }
        }
      }
    }
  }
}
</style>