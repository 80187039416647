import {defineStore} from 'pinia'

export const useCustomerDataStore = defineStore('useCustomerValue', {
    state: () => {
        return {
            customerData: null
        }
    },
    actions: {
        updateCustomerData(data) {
            this.customerData = data;
        },
        resetCustomerData() {
            this.customerData = null
        }
    },
    getters: {},
    persist: true
})
