import {defineStore} from 'pinia'


export const useOrderStore = defineStore('useOrder', {
    state: () => {
        return {
            // 결제화면 정보 조회
            orderData: null,
            changeDeliveryAddress: null,
            deliveryAddressMdeSeq: null,
            commoneSelectList: null,
            urlSave: null,
        }
    },
    actions: {
        updateOrderData (data) {
            this.orderData = data;
        },
        updateUrlSave (data) {
            this.urlSave = data
        },
        updateCommoneSelectList (list) {
            this.commoneSelectList = list;
        },
        updateChangeDeliveryAddress (data) {
            this.changeDeliveryAddress = data;
        },
        updateDeliveryAddressMdeSeq (data) {
            this.deliveryAddressMdeSeq = data;
        },
        resetOrderData () {
            this.orderData = null
        },
        restDeliveryAddressMdeSeq () {
            this.deliveryAddressMdeSeq = null
        },
        restChangeDeliveryAddress () {
            this.changeDeliveryAddress = null
        }
    },
    getters: {},
    persist: true
})
