<script>
import {onBeforeMount, reactive, toRefs} from "vue";
import {useRouter} from "vue-router";
import TitleHeaderVue from "@/components/TitleHeaderVue.vue";
import {useAuthStore} from "@/stores/useAuthStore";


export default {
  name: "NotFoundVue",
  components: {TitleHeaderVue},
  setup () {
    const authInfo = useAuthStore();
    const router = useRouter();
    const state = reactive({});
    onBeforeMount(() => {
      //TODO 이게 false 일때만 이페이지에 오게 끔
      if (authInfo.isRegisterSuc) {
      }
      authInfo.resetRegistrationValue();
    })
    onBeforeMount(() => {
      const body = document.getElementById("app");
      body.style.background = '#f2f3f7';
    });
    return {
      ...toRefs(state),
      router,
      handleBack () {
        router.go('-1');
      },
      handleReRegister () {
        router.push('/auth/register');
      }
    }
  }
}
</script>

<template>
  <div class="succes_register_wrap">
    <TitleHeaderVue title="뒤로가기" @back="handleBack"/>
    <div class="succes_register_inner">
      <div class="icon_content">
        <img alt="" src="@/assets/icon/fail_icon.svg"/>
      </div>
      <div class="notice_text_con">
        <p>
          <strong>죄송해요...</strong>
        </p>
      </div>
      <div class="notice_text_type_2">
        <p>존재 하지 않는 페이지 입니다</p>
        <!--        <p>조금 뒤에 다시 시도해주세요.</p>-->
      </div>
      <!--      <div class="submit_btn_wrap">-->
      <!--        <button class="submit_btn" type="button" @click="handleReRegister">-->
      <!--          회원가입-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.succes_register_wrap
{
  position   : relative;
  color      : #000;
  height     : 100vh;
  //min-height: 600px;
  max-height : 100vh;
  .succes_register_inner
  {
    .icon_content
    {
      margin-top    : 74px;
      margin-bottom : 24px;
      padding       : 0 32px;
      img
      {
        width  : 56px;
        height : 56px;
      }
    }
    .notice_text_con
    {
      height         : 84px;
      padding        : 0 32px;
      color          : #000;
      font-family    : AppleSDGothicNeoSB00;
      font-size      : 30px;
      font-style     : normal;
      font-weight    : 400;
      line-height    : 42px; /* 140% */
      letter-spacing : -1.2px;
    }
    .notice_text_type_2
    {
      margin-top     : 12px;
      padding        : 0 32px;
      height         : 48px;
      font-family    : AppleSDGothicNeoR00;
      font-size      : 16px;
      font-style     : normal;
      font-weight    : 400;
      line-height    : 24px; /* 150% */
      letter-spacing : -0.48px;
      color          : #999;
    }
    // 다음 버튼
    .submit_btn_wrap
    {
      position   : absolute;
      width      : 100%;
      padding    : 0 16px;
      height     : 56px;
      bottom     : 16px;
      text-align : center;
      left       : 0;
      .submit_btn
      {
        display        : inline-block;
        width          : 100%;
        height         : 100%;
        border-radius  : 12px;
        background     : #4583FF;
        font-family    : AppleSDGothicNeoB00;
        font-size      : 16px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 24px; /* 150% */
        letter-spacing : -0.48px;
        color          : #fff;
        text-align     : center;
      }
    }
  }
}
</style>