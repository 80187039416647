<script>
import {getCurrentInstance, reactive, toRefs} from "vue";
import {MyFuck} from "@/utill/maskingFuc";
import {useRouter} from "vue-router";


export default {
  name: "FooterVue",
  computed: {
    MyFuck () {
      return MyFuck
    }
  },
  setup () {
    const router = useRouter();
    const state = reactive({
      isDrop: false
    });
    const {emit} = getCurrentInstance();
    return {
      emit,
      router,
      ...toRefs(state),
      // 푸터 하단 회사정보 드롭다운
      handleFooterCompanyInfo () {
        state.isDrop = !state.isDrop;
      },
      handleLocation (path) {
        router.push(path);
      },
      handleSnsLocation () {
        window.open('https://www.instagram.com/haru_laundry', '_blank')
      },
      handleTerm (type) {
        emit('termsTitle', type);
      },
      handleAboutUs(){
        emit('companyInfo',true)
      }
    }
  }
}
</script>
<!--{{ MyFuck.hasMaskingString('대표이사') }}-->
<template>
  <div class="footer_wrap">
    <div class="footer_inner">
      <div class="link_list">
        <div class="link_list_item bold" @click="handleTerm('개인정보취급방침')">개인정보취급방침</div>
        <div class="link_list_item" @click="handleTerm('이용약관')">이용약관</div>
        <div class="link_list_item" @click="handleAboutUs">회사소개</div>
        <div class="link_list_item" @click="handleLocation('/customerService')">고객센터</div>
      </div>
      <div class="company_info_con">
        <div class="top_con" @click="handleFooterCompanyInfo">
          <p class="text_p">(주)하루세탁 (harulaundry Inc.)</p>
          <img v-if="!isDrop" alt="" src="@/assets/icon/common/bottom_arrow.svg"/>
          <img v-else alt="" src="@/assets/icon/common/top_arrow.svg"/>
        </div>
        <div class="company_info_list">
          <div class="company_info_list_item">
            <div :class="{isDrop: isDrop}" class="bottom_footer_text">
              <p class="info_text">운영시간: 오전 10시 ~ 오후 5시 (월~금)</p>
              <p class="info_text">점심시간: 오후 1시 00분 ~ 오후 2시 00분</p>
              <p class="info_text">문의방법: 카카오톡 ‘하루세탁’ 친구 추가</p>
              <p class="info_text">이메일: help@harulaundry.kr</p>
            </div>
            <p class="info_text" style="margin-top: 8px">대표이사: 박성은
              <mark class="bar"></mark>
              <span class="info_text">개인정보 보호책임자: 박성은</span></p>
            <p class="info_text"><span>사업자등록번호: 455-86-02935</span>
              <mark class="bar"></mark>
              <span class="info_text">사업자정보확인</span></p>
            <p class="info_text">본사: (04150) 서울 마포구 독막로36길 10-6 1층</p>
            <p class="info_text">통신판매업신고번호: 2023-서울마포-0431호</p>
            <div class="sns_link_con">
              <div class="company_joo">ⓒ 하루세탁 Corp All rights reserved</div>
            </div>
            <div class="sns_icon_wrap">
              <button type="button" @click="handleSnsLocation">
                <img alt="" src="@/assets/icon/mainLnbImage/instagram_icon.svg"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer_wrap
{
  border-top     : solid 1px #CED1D6;
  padding-bottom : 40px;
  .footer_inner
  {
    padding-top : 32px;
    .link_list
    {
      display         : flex;
      justify-content : flex-start;
      align-items     : center;
      .link_list_item
      {
        color          : #999;
        text-align     : center;
        font-family    : AppleSDGothicNeoSB00;
        font-size      : 12px;
        font-style     : normal;
        font-weight    : 400;
        line-height    : 18px; /* 150% */
        letter-spacing : -0.36px;
        &:not(:last-child)
        {
          &:after
          {
            content : '|';
            display : inline-block;
            color   : #CED1D6;
            margin  : 0 10px;
          }
        }
        &.bold
        {
          color          : #666;
          text-align     : center;
          font-family    : AppleSDGothicNeoSB00;
          font-size      : 12px;
          font-style     : normal;
          font-weight    : 400;
          line-height    : 18px; /* 150% */
          letter-spacing : -0.36px;
        }
      }
    }
    .company_info_con
    {
      margin-top : 24px;
      .top_con
      {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        margin-bottom   : 8px;
        .text_p
        {
          color          : #999;
          font-family    : AppleSDGothicNeoSB00;
          font-size      : 12px;
          font-style     : normal;
          font-weight    : 400;
          line-height    : 18px; /* 150% */
          letter-spacing : -0.36px;
        }
      }
      .company_info_list
      {
        overflow : hidden;
        .company_info_list_item
        {
          .sns_icon_wrap
          {
            margin-top: 16px;
            width  : 24px;
            height : 24px;
            button
            {
              display : block;
              img
              {
                width : 100%;
              }
            }
          }
          .info_text
          {
            color          : #999;
            font-family    : AppleSDGothicNeoR00;
            font-size      : 12px;
            font-style     : normal;
            font-weight    : 400;
            line-height    : 18px; /* 150% */
            letter-spacing : -0.36px;
            &.mttt{
              margin-top: 16px;
            }
          }
          .bar
          {
            position   : relative;
            top        : 2px;
            display    : inline-block;
            width      : 1px;
            height     : 12px;
            background : #999;
            margin     : 0 10px;
          }
          .bottom_footer_text
          {
            //margin-top : 16px;
            overflow   : hidden;
            max-height : 0;
            transition : all 1s cubic-bezier(0, 1, 0, 1);
            &.isDrop
            {
              //margin-bottom: 16px;
              max-height : 1500px;
              transition : all 1s ease-in-out;
            }
          }
          .sns_link_con
          {
            margin-top: 16px;
            display         : flex;
            justify-content : space-between;
            align-items     : center;
            .company_joo
            {
              color          : #999;
              text-align     : center;
              font-family    : AppleSDGothicNeoR00;
              font-size      : 12px;
              font-style     : normal;
              font-weight    : 400;
              line-height    : 18px;
              letter-spacing : -0.36px;
              margin-right   : auto;
            }
          }
        }
      }
    }
  }
}
</style>