import {createRouter, createWebHistory} from 'vue-router'
import auth from "@/router/auth";
import findId from "@/router/findId";
import product from "@/router/product";
import NotFoundVue from "@/components/NotFoundVue.vue";
import myPage from "@/router/myPage";
import category from "@/router/category";
import myCard from "@/router/myCard";
import myActivity from "@/router/myActivity";
import MainVue from "@/views/MainVue.vue";
import order from "@/router/order";
import customer from "@/router/customer";
import customerService from "@/router/customerService";
import alarm from "@/router/alarm";
import dryTip from "@/router/dryTip";

/* 코드 스플릿 청크 생성 렌더링 시간이 줄어듬 단점은 리소스를 계속 불러옴*/

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: '메인',
            component: MainVue,
            meta: {
                title: '하루세탁',
                metaTags: [
                    {
                        name: 'description',
                        content: "Nice description here"
                    },
                    {
                        name: 'keywords',
                        content: "all, of, my, keywords, here"
                    }
                ],
            }
        },
        auth,
        findId,
        product,
        myPage,
        category,
        myCard,
        myActivity,
        order,
        customer,
        customerService,
        alarm,
        dryTip,
        {
            path: '/:catchAll(.*)', // 수정된 path
            component: NotFoundVue,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return {top: 0}
    },
})

router.beforeResolve((to, from) => {
    document.title = to.meta.title || '하루세탁';
    if (to.meta.hasOwnProperty('metaTags')) {
        const oldMeta = document.getElementsByTagName('meta');
        for (let m = 0; m < oldMeta.length; m++) {
            for (let x = 0; x < to.meta.metaTags.length; x++) {
                if (oldMeta[m].name === to.meta.metaTags[x].name) {
                    oldMeta[m].remove();
                }
            }
        }

        for (let x = 0; x < to.meta.metaTags.length; x++) {
            const meta = document.createElement('meta');
            if (to.meta.metaTags[x].hasOwnProperty('name')) {
                meta.name = to.meta.metaTags[x].name;
                meta.content = to.meta.metaTags[x].content;
            }
            if (to.meta.metaTags[x].hasOwnProperty('property')) {
                meta.property = to.meta.metaTags[x].name;
                meta.content = to.meta.metaTags[x].content;
            }
            console.log('###########');
            console.log(meta);
            console.log('###########');
            document.head.appendChild(meta)
        }
    }
});
export default router;
