<script setup>
import {reactive} from "vue";


const props = defineProps({
  title: {
    type: String,
  }
})
const state = reactive({})
const emit = defineEmits(['back'])
const handleBackBtnClick = () => {
  emit('back')
}
</script>

<template>
  <div class="header_title_wrap">
    <button class="back_btn" type="button" @click="handleBackBtnClick">
      <img alt="" src="@/assets/icon/common/back_icon.svg"/>
    </button>
    <h2 class="header_title">{{ props.title ?? props.title }}</h2>
  </div>
</template>

<style lang="scss" scoped>
.header_title_wrap
{
  position        : relative;
  display         : flex;
  justify-content : center;
  width           : 100%;
  padding         : 18px 24px;
  .back_btn
  {
    position   : absolute;
    top        : 50%;
    left       : 24px;
    transform  : translateY(-50%);
    flex-basis : 24px;
    width      : 24px;
    height     : 24px;
  }
  .header_title
  {
    flex-basis     : 100%;
    width          : 100%;
    text-align     : center;
    font-family    : AppleSDGothicNeoSB00;
    font-size      : 16px;
    font-style     : normal;
    font-weight    : 400;
    line-height    : 24px; /* 150% */
    letter-spacing : -0.48px;
  }
}
</style>