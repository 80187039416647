export default {
    path: '/category',
    children: [
        {
            path: '',
            name: 'category',
            label: '카테고리',
            component: () => import('@/views/category/CategoryVue.vue'),
            meta:{
                title:'하루세탁'
            }
        },
    ]
}
