import mainApi from "@/api/main/mainApi";


export class MainApiRequest {
    /**
     * 하루세탁 쿠폰 리스트 전부 api
     * @param param
     * @returns {Promise<any>}
     */
    static async getProductAllCouponRequest (param) {
        try {
            const data = await mainApi.getProductCouponList(param);
            return data.data;
        } catch (error) {
            console.error(error);
            throw new Error(error);
        }
    }
}