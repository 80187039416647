export const maskString = (str) => {
    if (str) {
        if (str.length <= 2) {
            return str; // Return the original string if it has 2 or fewer characters
        }

        const firstChar = str.charAt(0);
        const lastChar = str.charAt(str.length - 1);
        const maskedChars = '*'.repeat(str.length - 2);

        return firstChar + maskedChars + lastChar;
    }

}

export class MyFuck {
    static hasMaskingString(str) {
        if (str.length <= 2) {
            return str; // Return the original string if it has 2 or fewer characters
        }

        const firstChar = str.charAt(0);
        const lastChar = str.charAt(str.length - 1);
        const maskedChars = '*'.repeat(str.length - 2);

        return firstChar + maskedChars + lastChar;
    }
}