<script>
import AlarmVue from "@/components/common/AlarmVue.vue";
import {getCurrentInstance, onBeforeMount, reactive, toRefs} from "vue";
import {AlarmApiRequest} from "@/api/alarmapi/alarmApiRequest";
import {useAuthStore} from "@/stores/useAuthStore";


export default {
  name: "AlarmHeaderVue",
  components: {AlarmVue},
  props: ['newAlarm'],
  setup () {
    const state = reactive({
      newAlarmCheck: false
    });
    const {props} = getCurrentInstance();
    const authInfo = useAuthStore();
    onBeforeMount(() => {
      if (authInfo.memberToken) {
        AlarmApiRequest.alarmListRequest({
          member_seq: authInfo.memberInfo.member_seq
        }).then(res => {
          if (Object.keys(res.data).includes('result') && res.data.result.length > 0) {
            console.log("-> res.data.result;", res.data.result);
            res.data.result.forEach((item) => {
              if (item.read_yn === 'N') {
                state.newAlarmCheck = true;
              }
            })
          }
        });
      }
    });
    return {
      props,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="alarm_header_wrap">
    <div class="alarm_header_inner">
      <div class="logo_con">
        <img alt="" src="@/assets/icon/logo_icon.svg"/>
      </div>
      <div class="alarm_con">
        <AlarmVue :newAlarmCheck="newAlarmCheck"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alarm_header_wrap
{
  .alarm_header_inner
  {
    height          : 60px;
    padding         : 0 24px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    .logo_con
    {
      margin-right : auto;
      width        : 108px;
      height       : 22px;
      img
      {
        width : 100%;
      }
    }
    .alarm_con
    {
      margin-left : auto;
    }
  }
}
</style>