<script>

import AlarmHeaderVue from "@Components/common/AlarmHeaderVue.vue";
import AlarmVue from "@Components/common/AlarmVue.vue";

export default {
  name: 'LoadMianContentVue',
  components: {AlarmVue, AlarmHeaderVue},
}
</script>

<template>
  <div  class="main_wrapper">
    <div class="main_inner">
      <div class="main_header_con">
        <div class="alarm_header_wrap">
          <div class="alarm_header_inner">
            <div class="logo_con">
              <img alt="" src="@/assets/icon/logo_icon.svg"/>
            </div>
            <div class="alarm_con">
              <AlarmVue/>
            </div>
          </div>
        </div>
      </div>
      <div class="main_content">
        <div class="main_top">
          <p class="notice_text">
            <q-skeleton type="text" width="80%" />
          </p>
          <div class="middle_box">
            <q-skeleton type="text" width="100%" />
            <q-skeleton type="text" width="70%" />
            <q-skeleton height="64px" style="margin-top: 20px" type="rect" width="100%" />
            <q-skeleton height="200px" style="margin-top: 20px" type="rect" width="100%" />
          </div>
        </div>
        <div class="main_bottom" style="padding: 0 24px">
          <q-skeleton type="text" width="70%" />
          <q-item style="max-width: 300px">
            <q-item-section avatar>
              <q-skeleton type="QAvatar" />
            </q-item-section>

            <q-item-section>
              <q-item-label>
                <q-skeleton type="text" />
              </q-item-label>
              <q-item-label caption>
                <q-skeleton type="text" width="65%" />
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item style="max-width: 300px">
            <q-item-section avatar>
              <q-skeleton type="QAvatar" />
            </q-item-section>

            <q-item-section>
              <q-item-label>
                <q-skeleton type="text" />
              </q-item-label>
              <q-item-label caption>
                <q-skeleton type="text" width="65%" />
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item style="max-width: 300px">
            <q-item-section avatar>
              <q-skeleton type="QAvatar" />
            </q-item-section>

            <q-item-section>
              <q-item-label>
                <q-skeleton type="text" />
              </q-item-label>
              <q-item-label caption>
                <q-skeleton type="text" width="65%" />
              </q-item-label>
            </q-item-section>
          </q-item>
        </div>
        <div class="footer" style="margin-top: 20px">
          <q-skeleton type="text" width="100%" />
          <q-skeleton type="text" width="100%" />
          <q-skeleton type="text" width="100%" /><q-skeleton type="text" width="100%" />
          <q-skeleton type="text" width="100%" />
        </div>
      </div>
    </div>
    <div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.alarm_header_wrap{
  .alarm_header_inner{
    height: 60px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo_con{
      margin-right: auto;
      width: 108px;
      height: 22px;
      img{
        width: 100%;
      }
    }
    .alarm_con{
      margin-left: auto;
    }
  }
}
.main_wrapper{
  font-size: 1.6em;
  .main_inner{
    .main_header_con{
      background: #BCD5FE;
    }
    .main_content{
      padding-bottom: 64px;
      .main_top{
        position: relative;
        padding: 0 24px;
        height:283px;
        background:#BCD5FE;
        .notice_text{
          padding-top: 24px;
          font-family: AppleSDGothicNeoR00;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 140% */
          letter-spacing: -0.8px;
          &.st{
            font-family: AppleSDGothicNeoB00;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.8px;
          }
        }
        .middle_box{
          position: relative;
          margin-top: 16px;
          padding: 32px 24px 0 24px;
          background: #fff;
          border-radius: 12px;
          min-height: 444px;
        }
      }
      .main_bottom{
        margin-top: 270px;
      }
      .footer{
        padding: 0 24px;
      }
    }
  }
}
</style>
