<script setup>

import {onMounted, reactive} from "vue";
import channelToke, {ChannelService} from "@/utill/channelToke";
import {useAuthStore} from "@/stores/useAuthStore";


const auth = useAuthStore();
const props = defineProps({
  bottom: String,
  right: String,
});
const state = reactive({
  unReadCount: 0,
})
const startedChannelTalk = (user) => {
  const channelTalk = new ChannelService();
  channelTalk.boot({
    pluginKey: '65daa9ac-e2a3-4ea6-8e49-723bc155697e',
    customLauncherSelector: ".channel_talk_btn",
    hideChannelButtonOnBoot: true,
    // memberId:'65262e6834415361b079'
  },(data,datat)=>{
    console.log("-> data", data);
    console.log("-> datat", datat);
    state.unReadCount = datat.unread;
  })
  channelTalk.onBadgeChanged((datra)=>{
    console.log("-> datra", datra);
    state.unReadCount = datra;
  })
}

onMounted(() => {
  startedChannelTalk();
});
</script>

<template>
  <div :style="{bottom: props.bottom +'px' ,right:props.right + 'px'}" class="channel_talk_wrap">
    <button class="channel_talk_btn" type="button">
      <span class="badge" v-if="state.unReadCount !== 0">{{state.unReadCount}}</span>
      <img src="@/assets/icon/chanalTalkIcon.svg" alt="">
    </button>
  </div>
</template>

<style lang="scss" scoped>
.channel_talk_wrap
{
  position : fixed;
  z-index: 100;
  .channel_talk_btn
  {
    position: relative;
    .badge{
      position: absolute;
      top:0;
      right: 0;
      width: 16px;
      height: 16px;
      background: #F33B6E;
      border-radius: 50%;
      color: var(--white, #FFF);
      font-family: AppleSDGothicNeoSB00;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.36px;
      text-align: center;
    }
  }
}
</style>