export default {
  path: '/auth',
  children: [
    {
      path: 'join',
      name: 'auth.join',
      label: '로그인',
      component: () => import('@/views/auth/LoginVue.vue'),
      meta:{
        title:'로그인'
      }
    },
    {
      path: 'join_type_b',
      name: 'auth.join_type_b',
      label: '패스워드입력',
      component: () => import('@/views/auth/LoginTypeBVue.vue')
    },
    {
      path: 'register',
      name: 'auth.register',
      label: '회원가입',
      component: () => import('@/views/auth/RegisterVue.vue')
    },
    {
      path: 'singin_A',
      name: 'auth.singin_A',
      label: '약관동의',
      component: () => import('@/views/auth/SignInVue.vue')
    },
    {
      path: 'singin_B',
      name: 'auth.singin_B',
      label: '정보입력',
      component: () => import('@/views/auth/SignInTypeBVue.vue')
    },
    {
      path: 'singin_C',
      name: 'auth.singin_C',
      label: '정보입력',
      component: () => import('@/views/auth/SignInTypeCVue.vue')
    },
    {
      path: 'singin_D',
      name: 'auth.singin_D',
      label: '정보입력',
      component: () => import('@/views/auth/SignInTypeDVue.vue')
    },
    {
      path: 'success',
      name: 'auth.success',
      label: '회원가입완료',
      component: () => import('@/views/auth/SuccesRegisterVue.vue')
    },
    {
      path: 'fail',
      name: 'auth.fail',
      label: '회원가입실패',
      component: () => import('@/views/auth/FalidRegisterVue.vue')
    },
  ]
}
