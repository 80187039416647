<script>
import {reactive, toRefs} from "vue";


export default {
  name: "ModalVue",
  setup () {
    const state = reactive({
      modalStatus: false
    });

    // modal 오픈
    const showModal = () => {
      console.log('나왔음')
      state.modalStatus = true;
    }
    // modal 클로즈
    const hideModal = () => {
      state.modalStatus = false;
    }
    return {
      ...toRefs(state),
      showModal,
      hideModal,
    }
  }
}
</script>

<template>
  <div v-show="modalStatus" class="modal_wrapper_area">
    <div class="modal_inner_content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal_wrapper_area
{
  position   : fixed;
  top        : 0;
  left       : 50%;
  transform  : translateX(-50%);
  width      : 100vw;
  max-width  : 500px;
  height     : 100%;
  background : rgba(0, 0, 0, 0.5);
  z-index    : 99999;
  .modal_inner_content
  {
    position      : absolute;
    top           : 50%;
    left          : 50%;
    transform     : translate(-50%, -50%);
    background    : #fff;
    width         : calc(100% - 24px);
    min-height    : 176px;
    border-radius : 12px;
  }
}
</style>