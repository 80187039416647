export default {
    path: '/product',
    children: [
        {
            path: 'detail/:id',
            name: 'product.detail',
            label: '상품상세',
            component: () => import('@/views/product/ProductWrapVue.vue'),
            meta:{
                title:'하루세탁'
            }
        },
    ]
}
